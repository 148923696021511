<template>
  <div id="app" style="background-color: #eeeeee">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Programacion web con Python y Javascript - vue y django',
    titleTemplate: null,
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Contenido sobre programacion web con python y django, javascript con vuejs, gratuitos y de paga.',
    }],
    htmlAttrs: {
      lang: 'es-Mx',
      amp: true
    },
  }
}
</script>
<style lang="scss" src="./scss/styles.scss">
</style>
