<template>
  <div class="grid-x grid-margin-x blogs">
    <h3
      class="cell blogs__title"
    >
      {{ titulo }}
    </h3>

    <div class="cell grid-x grid-margin-x">
      <div 
        class="cell small-12 medium-6 large-4"
        v-for="blog in listaBlogs" :key="blog.id"
      >
        <v-card-text 
          :titulo="blog.title"
          :categoria="blog.category.name"
          :link="{name: 'blog-detail', params: {slug: blog.slug}}"
          :imagen="blog.image"
        />
      </div>
    </div>
  </div>
</template>

<script>
  //api
  import apiBlog from '@/api/blog'
  //
  import VCardText from '@/components/base/VCardText'

  export default {
    name: 'ListaBlogsMasVisitados',
    props: {
      size: {
        type: Number,
        default: 3
      },
      tipo: {
        type: String,
        default: '1'
      },
      titulo: String
    },
    data() {
      return {
        listaBlogs: []
      }
    },
    components: {
      VCardText
    },
    methods: {
      listarBlogs: function () {
        apiBlog.listaBlogMasVisitados(this.tipo, this.size).then(
          (response) => {
            this.listaBlogs = response.data
          }
        )
      }
    },
    beforeMount() {
      this.listarBlogs()
    },
  }
</script>

<style lang="scss" scoped>
  .blogs {
    padding: 10px;
    margin-bottom: 1em;
    &__title {
      font-family: $n-ff-2;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 1em;
      text-transform: capitalize;
      color: $n-color2;
    }
  }
</style>