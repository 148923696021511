<template>
  <div class="new-tema">
    <form @submit.prevent="revisarCapcha()" class="new-tema__content">
      <h3 class="new-tema__content__title">Recomiéndanos un tema a tratar </h3>
      <input 
        type="email" 
        placeholder="email..."
        class="new-tema__content__txt"
        v-model="email">
      <textarea 
        rows="3"
        class="new-tema__content__txt"
        placeholder="recomendacion..."
        v-model="mensaje">
      </textarea>

      <button 
        type="submit"
        class="new-tema__content__btn">
          Enviar
      </button>
      <button 
        type="button"
        class="new-tema__content__btn alert"
        @click="method()">
          Cancelar
      </button>
    </form>
    <app-loading v-if="loading"/>
    <app-success 
      v-if="isSuccess"
      texto="Gracias por enviar tu sugerencia..."
      color="white"
      font-family="Roboto"/>
  </div>
</template>

<script>
  // thry party apps
  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'

  Vue.use(VueReCaptcha, { siteKey: '6LdpqtIZAAAAAGn3FlR8hal0_mr4HKpvG7xAc7Kw' })
  // apps
  import AppLoading from '@/components/apps/AppLoading'
  import AppSuccess from '@/components/apps/AppSuccess'
  import { mapGetters } from 'vuex'
  //
  import apiHome from '@/api/home'

  export default {
    name: 'RecomendarTema',
    components: {
      AppLoading,
      AppSuccess
    },
    props: {
      method: {type: Function}
    },
    data() {
      return {
        email: '',
        mensaje: '',
        isSuccess: false,
        loading: false
      }
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        userData: 'userData'
      })
    },
    methods: {
      revisarCapcha: async function() {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')
        if (token) {
          this.enviarRecomendacion()
        } 
      },
      enviarRecomendacion: function () {
        this.loading = true
        let data = {
          'tipo': '1',
          'email': this.email,
          'mensjae': this.mensaje
        }
        apiHome.sendMensaje(data).then(
          (response) => {
            if (response.data.id) {
              this.isSuccess = true
              setTimeout(()=>{
                this.method()
                this.isSuccess = false
              },1500);
            }
            this.loading = false
          }
        ).catch (
          (error) => {
            console.log(error)
            this.loading = false
          }
        )
      }
    },
    beforeMount() {
      if (this.isLoggedIn && this.userData) {
        this.email = this.userData.email
      }
    },
  }
</script>

<style lang="scss" scoped>
  .new-tema {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(85, 84, 84, 0.5);
    z-index: 300;
    &__content {
      width: 400px;
      background: $n-color-white;
      padding: 1em;
      border-radius: 10px;
      &__title {
        font-family: $n-ff-2;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 1.5em;
      }
      &__txt {
        font-family: $n-ff-1;
        color: $n-color2;
        border-radius: 10px;
      }
      &__btn {
        display: block;
        text-align: center;
        background: $n-color5;
        color: $n-color-white;
        font-family: $n-ff-2;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-radius: 10px;
        margin-bottom: 10px;
        &:hover {
          opacity: 0.6;
          cursor: pointer;
        }
        &.alert {
          background: $n-color2;
        }
      }
    }
  }
</style>