<template>
  <div>
    <preview-answer-user />
  </div>
</template>

<script>

  import PreviewAnswerUser from '@/components/users/PreviewAnswerUser'

  export default {
    name: 'PreviewAnswerPage',
    components: {
      PreviewAnswerUser
    }
  }
</script>

<style lang="scss" scoped>

</style>