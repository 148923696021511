<template>
  <div>
    <div class="grid-x grid-margin-x">
      <div class="cell small-12 large-3 small-order-2 large-order-1">
        <TheMenuForo 
          :screen="screenSize"
        />
      </div>
      <div class="cell small-12 large-9 small-order-1 large-order-2">
        <TheBannerForo />
        <!-- aqui va el contenido de la pagina -->
        <div>
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TheBannerForo from '@/components/foro/TheBannerForo'
import TheMenuForo from '@/components/foro/TheMenuForo'

export default {
  name: 'ForoPage',
  metaInfo: {
    title: 'Guia de programacion web',
    titleTemplate: null,
    meta: [{
      vmid: 'description',
      name: 'description',
      content: 'Preguntas frecuentes de Django y vue',
    }]
  },
  data() {
    return {
    }
  },
  computed: {
    screenSize: function () {
      return window.innerWidth
    }
  },
  components: {
    TheBannerForo,
    TheMenuForo
  },
}
</script>