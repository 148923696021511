<template>
  <div class="card-blog">
    <h3 class="card-blog__title">{{ titulo }}</h3>
    <p class="card-blog__resume">{{ resumen }}</p>
    <div class="card-blog__icons">
      <v-icon-text-inline
        v-for="icon in iconsText"
        :key="icon.id" 
        :iconComponent="icon.icono"
        :texto="icon.name"
      />
    </div>
    <div class="card-blog__content">
      <div
        v-if="video" >
         <v-video-youtube :src-video="video" /> 
      </div>
      <div v-else>
        <img
          class="card-blog__content__img" 
          :src="image" 
          @error="setAltImg"
        >
      </div>
      
      <div class="card-blog__content__text" v-html="texto">
      </div>
      <span class="card-blog__content__date">{{ fecha }}</span>
    </div>
  </div>
</template>

<script>

  import VIconTextInline from '@/components/base/VIconTextInline'
  import VVideoYoutube from '@/components/base/VVideoYoutube'
  import notImg from '@/assets/001.png'

  export default {
    name: 'VCardBlog',
    props: {
      titulo: String,
      resumen: String,
      iconsText: Array,
      video: String,
      image: String,
      texto: String,
      fecha: String
    },
    components: {
      VIconTextInline,
      VVideoYoutube
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    }
  }
</script>

<style lang="scss" scoped>
  .card-blog {
    background: white;
    padding: 1em;
    width: 100%;
    &__title {
      font-family: $n-ff-1;
      font-size: 18px;
      font-weight: bold;
      color: $n-color2;
      @include desde (1000px) {
        font-size: 22px;
      }
    }
    &__resume {
      font-size: 14px;
      font-family: $n-ff-2;
      color: $n-color2;
      @include desde (1000px) {
        font-size: 16px;
      }
    }
    &__icons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px;
      background: $n-color8;
      margin-bottom: 1em;
      border-radius: 10px;
    }
    &__content {
      &__img {
        width: 100%;
        height: 300px;
        @include desde (1000px) {
          height: 430px;
        }
        @include desde(1904px) {
          height: 530px;
        }
      }
      &__text {
        display: block;
        font-size: 15px;
        font-family: $n-ff-1;
        @include desde (1000px) {
          font-size: 16px;
        }
      }
      &__date {
        border: 1px solid;
        padding: 3px 15px 3px 15px;
        border-radius: 10px;
        color: $n-color3;
        font-size: 12px;
      }
    }
  }
</style>