<template>
  <div class="card-image">
    <div class="card-image__header">
      <img 
        class="card-image__header__img"
        :src="mainImage" 
        @error="setAltImg"
      >
      <span class="card-image__header__tag">{{ precio }} $</span>
    </div>
    <div class="card-image__body">
      <router-link 
        :to="link"
        class="card-image__body__title">{{ titulo }}
      </router-link>
      <div class="card-image__body__detail">
        <img 
          class="card-image__body__detail__img"
          :src="image" 
        >
        <p class="card-image__body__detail__text">{{ detalle }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import notImg from '@/assets/001.png'

  export default {
    name: 'VCardImage',
    props: {
      precio: Number,
      titulo: String,
      detalle: String,
      mainImage: String,
      image: String,
      link: Object
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    },
  }
</script>

<style lang="scss" scoped>
  .card-image {
    padding: 10px;
    &__header {
      position: relative;
      &__img {
        display: block;
        width: 100%;
        height: 180px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        @include desde(1000px) {
          height: 120px;
        }
        &:hover {
          opacity: 0.6;
        } 
      }
      &__tag {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-family: $n-ff-4;
        color: $n-color-white;
        padding: 3px 1em 3px 1em;
        border-radius: 10px;
        background: rgba(20, 27, 27, 0.404);
      }
    }
    &__body {
      background: $n-color-white;
      padding: 10px;
      border-radius: 10px;
      box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
      &__title {
        display: block;
        font-family: $n-ff-1;
        color: $n-color2;
        font-size: 14px;
        height: 30px;
        overflow: hidden;
      }
      &__detail {
        display: flex;
        align-items: center;
        &__img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 0px;
        }
        &__text {
          margin-bottom: 0px;
          margin-left: 10px;
          font-size: 12px;
          font-family: $n-ff-4;
        }
      }
    }
  }
</style>