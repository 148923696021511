// services for user api
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import global settings
import baseConf from '@/Base'

Vue.use(VueAxios, axios)

// instance for atentication
var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 12000
})

const apiHome = {
  getHome: function () {
    return instance.get('/api/get-home/')
  },
  sendMensaje: function (data) {
    return instance.post('/api/create-msj/', data)
  }
}

export default apiHome