// importamos vistas locales
import LoginPage from '@/views/users/LoginPage'
import ProfileUserPage from '@/views/users/ProfileUserPage'
import QuestionUsersPage from '@/views/users/QuestionUsersPage'
import AnswerUserPage from '@/views/users/AnswerUserPage'
import LegoUserPage from '@/views/users/LegoUserPage'
import PreviewAnswerPage from '@/views/users/PreviewAnswerPage'
import PreviewQuestionPage from '@/views/users/PreviewQuestionPage'


const usersRutes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/user/answer/id/:id',
    name: 'preview-answer',
    component: PreviewAnswerPage,
  },
  {
    path: '/user/question/id/:id',
    name: 'preview-question',
    component: PreviewQuestionPage,
  },
  {
    path: '/user/perfil',
    component: ProfileUserPage,
    children: [
      {
        path: '',
        name: 'user-profile',
        component: QuestionUsersPage
      },
      {
        path: 'respuestas',
        name: 'user-answers',
        component: AnswerUserPage
      },
      {
        path: 'lego',
        name: 'user-lego',
        component: LegoUserPage
      },  
    ]
  }
]

export default usersRutes