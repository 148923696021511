<template>
  <div class="grid-x grid-margin-x">
    <h3 class="cell foro-title">
      Preguntas Frecuentes
    </h3>
    <div class="cell">
      <lista-preguntas-populares />
    </div>
  </div>
</template>

<script>

import ListaPreguntasPopulares from '@/components/foro/ListaPreguntasPopulares'

export default {
  name: 'ForoPreguntasPopulares',
  components: {
    ListaPreguntasPopulares
  }
}
</script>

<style lang="scss" scoped>
  .foro-title {
    margin-bottom: 1em;
    margin-top: 1em;
    font-family: $n-ff-1;
    font-size: 16px;
    color: $n-color3;
    font-weight: bold;
  }
</style>