<template>
  <div>
    <preview-question-user />
  </div>
</template>

<script>

  import PreviewQuestionUser from '@/components/users/PreviewQuestionUser'

  export default {
    name: 'PreviewQuestionPage',
    components: {
      PreviewQuestionUser
    }
  }
</script>

<style lang="scss" scoped>

</style>