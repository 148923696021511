<template>
  <div class="results">
    <ul class="results__lista">
      <a @click="method()" class="results__lista__close">x</a>
      <li 
        class="results__lista__item"
        v-for="resultado in resultados"
        :key="resultado.id">
        <router-link 
          @click.native="method()"
          :to="{ name: 'blog-detail', params: { slug: resultado.slug }}"
          class="results__lista__item__link">
          {{ resultado.title }}
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'BlogSearchResults',
    props: {
      resultados: Array,
      method:{type: Function}
    }
  }
</script>

<style lang="scss" scoped>
  .results {
    position: absolute;
    width: 100%;
    top: 50px;
    left: 0px;
    z-index: 100;
    background: $n-color-white;
    border-radius: 10px;
    &__lista {
      display: block;
      padding: 0px;
      margin: 0px;
      list-style: none;
      &__item {
        display: block;
        &__link {
          display: block;
          padding: 7px 15px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: $n-color2;
        }
      }
      &__close {
        position: absolute;
        right: 10px;
        top: -40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        background: red;
        color: $n-color-white;
        font-family: $n-ff-1;
        &:hover {
          color: white;
        }
      }
    }
  }
</style>