<template>
  <div class="grid-x grid-margin-x posts">
    <div 
      class="cell small-12"
      v-for="pregunta in listaPreguntas"
      :key="pregunta.id">
      <v-card-post 
        :color="pregunta.curso.color"
        :imagen="pregunta.curso.image"
        :contenido="pregunta.title +  ' ' + pregunta.content "
        :fecha="pregunta.created.toString()"
        :tag="pregunta.curso.lenguaje"
        :respuestas="pregunta.num_answer"
        :link="{name:'pregunta-detail', params: {slug: pregunta.slug}}"
      />
    </div>
  </div>
</template>

<script>
  //
  import apiPregunta from '@/api/pregunta'
  //
  import { mapGetters, mapActions } from 'vuex'
  import VCardPost from '@/components/base/VCardPost'


  export default {
    name: 'ListaPreguntasPopulares',
    components: {
      VCardPost
    },
    data() {
      return {
        listaPreguntas: []
      }
    },
    computed: {
      ...mapGetters({
        kword_question: 'kword_question',
        curso_question: 'curso_question'
      })
    },
    watch: {
      kword_question: function () {
        this.listarPreguntaPopulares()
      },
      curso_question: function () {
        this.listarPreguntaPopulares()
      }
    },
    methods: {
      ...mapActions({
        SetKwordQuestion: 'SetKwordQuestion',
        SetCursoQuestion: 'SetCursoQuestion'
      }),
      listarPreguntaPopulares: function () {
        apiPregunta.listaPreguntasPopulares(this.kword_question, this.curso_question).then(
          (response) => {
            this.listaPreguntas = response.data.results
          }
        ).catch(
          () => []
        )
      }
    },
    beforeMount() {
      this.SetKwordQuestion('')
      this.SetCursoQuestion(-1)
      this.listarPreguntaPopulares()
    },
  }
</script>

<style lang="sass" scoped>

</style>