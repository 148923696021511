<template>
  <div class="flotaing-menu">
    <ul 
      class="flotaing-menu__ul"
      v-show="show">
      <li class="flotaing-menu__ul__li">
        <router-link
          :to="{name: 'lego', params: {'categoria': 'populares'}}"
          class="flotaing-menu__ul__li__a">
          <store-outline :size="15" style="margin: 0px"/>
        </router-link>
      </li>
      <li class="flotaing-menu__ul__li">
        <router-link
          :to="{name: 'foro'}"
          class="flotaing-menu__ul__li__a">
          <comment-search-outline  :size="15"/>
        </router-link>
      </li>
      <li class="flotaing-menu__ul__li">
        <router-link
          :to="{name: 'blog'}"
          class="flotaing-menu__ul__li__a">
          <newspaper-variant-outline :size="15"/>
        </router-link>
      </li>
      <li class="flotaing-menu__ul__li">
        <router-link
          v-if="isLoggedIn"
          :to="{name: 'user-profile'}"
          class="flotaing-menu__ul__li__a">
          <account-outline :size="15" />
        </router-link>
        <router-link
          v-else
          :to="{name: 'login'}"
          class="flotaing-menu__ul__li__a">
          <account-outline :size="15" />
        </router-link>
      </li>
      <li class="flotaing-menu__ul__li">
        <router-link
          :to="{name: 'cursos'}"
          class="flotaing-menu__ul__li__a">
          <book-multiple :size="15" />
        </router-link>
      </li>
    </ul>
    <a @click="show = !show"
      class="flotaing-menu__icono">
      <menu-icon  :size="18"/>
    </a>
  </div>
</template>

<script>

  import { mapGetters } from 'vuex'

  export default {
    name: 'TheFlotaingMenu',
    data() {
      return {
        show: false,
      }
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      })
    },
  }
</script>

<style lang="scss">
  .flotaing-menu {
    @include desde(1000px){
      display: none;
    }
    &__icono {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      position: fixed;
      bottom: 40px;
      right: 10px;
      border-radius: 50%;
      background-color: $n-color1;
      padding: 20px;
      z-index: 500;
    }
    &__ul {
      position: fixed;
      bottom: 80px;
      right: 10px;
      list-style: none;
      margin: 0px;
      z-index: 500;
      &__li {
        display: block;
        margin-bottom: 5px;
        &__a {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          padding: 5px;
          border: 1px solid $n-color-white;
          border-radius: 50%;
          margin: 0px;
          background: $n-color1;
          color: $n-color-white;
        }
      }
    }
  }
</style>