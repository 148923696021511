// importamos los tipos de mutacion

import * as types from './mutation-types'

const matutaions = {
  [types.SET_VENTA] (state, venta) {
    // setamos la lista de resultados de busqueda lego
    state.venta = venta
  },
}

export default matutaions
