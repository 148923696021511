<template>
  <div>
    <div class="header-main">
      <div class="header-main__logo">
        <img 
          src="../../assets/logo_neunapp.png" 
          alt=""
          class="header-main__logo__img">
        <a 
          href="/"
          class="header-main__logo__link">Neunapp</a>
      </div>
      <div class="header-main__search">
        <magnify
          class="header-main__search__icon" 
          fillColor="#bdbdbd"
          :size="18" 
        />
        <input 
          type="text"
          class="header-main__search__input"
          placeholder="Buscar artículo ..."
          v-model="kword">
        
        <blog-search-results 
          v-if="showResults"
          :resultados="listaBlogs"
          :method="cerrarResultados"
        />
      </div>
      <div class="header-main__options">
        <VButtonPrimary 
          :link="{ name: 'cursos', paramas: {} }"
          text="Cursos"
        />
        <VButtonPrimary
          v-if="isLoggedIn"
          :link="{ name: 'user-profile', paramas: {} }"
          text="Perfil"
          style="border: 2px #8e44ad solid"
        />
        <VButtonPrimary 
          v-else
          :link="{ name: 'login', paramas: {} }"
          text="Login"
        />
        <router-link
          :to="{ name: 'blog'}"
          title="Blog"
          class="header-main__options__link">
            <newspaper-variant-outline  />
        </router-link>
        <router-link
          :to="{ name: 'foro'}"
          title="Foro"
          class="header-main__options__link">
            <comment-search-outline  />
        </router-link>
        <div class="header-main__options__notification">
          <router-link 
            :to="{ name: 'lego', params: {'categoria': 'populares' }}"
            class="header-main__options__link">
              <store-outline />
          </router-link>
          <span
            class="header-main__options__notification__tag">
            3
          </span>
        </div>
      </div>
    </div>
    <the-flotaing-menu />
  </div>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'
  import apiBlog from '@/api/blog'

  import VButtonPrimary from '@/components/base/VButtonPrimary'
  import TheFlotaingMenu from '@/components/includes/TheFlotaingMenu'
  import BlogSearchResults from '@/components/blogs/BlogSearchResults'

  export default {
    name: 'TheHeader',
    components: {
      VButtonPrimary,
      TheFlotaingMenu,
      BlogSearchResults
    },
    data() {
      return {
        listaBlogs: [],
        kword: '',
        showResults: false
      }
    },
    watch: {
      kword: function (val) {
        this.buscarBlogs(val)
      }
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      })
    },
    methods: {
      ...mapActions({
        recuperarUsuario: 'recuperarUsuario',
      }),
      buscarBlogs: function (kword) {
        apiBlog.buscarBlogs(kword).then(
          (response) => {
            this.listaBlogs = response.data.results
            if (response.data.results.length) {
              this.showResults = true
            }
          }
        )
      },
      cerrarResultados: function () {
        this.showResults = false
      }
    },
    created() {
      this.recuperarUsuario()
    },
  }
</script>

<style lang="scss" scoped>
  .header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 2em 1em 2em;
    background-color: #fafafa;
    margin-bottom: 1em;
    &__logo {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 20%;
      @include hasta(1000px) {
        width: 100%;
        justify-content: center;
      }
      &__img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 10px;
        @include hasta(1000px) {
          width: 40px;
          height: 40px;
        }
      }
      &__link {
        font-family: $n-ff-1;
        font-family: $n-color1;
        font-size: 25px;
        font-weight: bold;
        @include hasta(1000px) {
          font-size: 20px;
        }
      }
    }
    &__search {
      position: relative;
      display: flex;
      align-items: center;
      width: 38%;
      @include hasta(1000px) {
        display: none;
      }
      &__input {
        display: block;
        width: 100%;
        height: 40px;
        border-radius: 15px;
        padding-left: 30px;
        background: #eceff1;
        font-family: $n-ff-4;
        margin: 0px;
        &:focus {
          border-color: $n-color1;
        }
      }
      &__icon {
        position: absolute;
        left: 10px;
        bottom: 2px;
      }
    }
    &__options {
      width: 32%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include hasta(1000px) {
        display: none;
      }
      &__link {
        color: $n-color2;
      }
      &__notification {
        position: relative;
        &__tag {
          position: absolute;
          top: 0px;
          left: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          background-color: $n-color4;
          color: $n-color-white;
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }
</style>