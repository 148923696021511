<template>
  <div class="grid-x grid-margin-x">
    <div class="cell small-12 medium-2 large-3 only-pc">
      <TheLegoFilters />
    </div>
    <div class="cell small-12 medium-10 large-9">
      <the-lego-banner />
      <donations-block />
      <the-lego-search />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  import TheLegoBanner from '@/components/lego/TheLegoBanner'
  import TheLegoFilters from '@/components/lego/TheLegoFilters'
  import DonationsBlock from '@/components/includes/DonationsBlock'
  import TheLegoSearch from '@/components/lego/TheLegoSearch'

  export default {
    name: 'LegoPage',
    metaInfo: {
      title: 'Templates, plantillas y ejemplos django, vue, Javascript',
      titleTemplate: null,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Plugin y paquetes de terceros de Django y vue',
      }]
    },
    components: {
      TheLegoBanner,
      TheLegoFilters,
      DonationsBlock,
      TheLegoSearch,
    }
  }
</script>

<style lang="scss" scoped>

</style>