// importamos los tipos de mutacion

import * as types from './mutation-types'

const matutaions = {
  [types.SET_KWORD] (state, kword) {
    // setamos la lista de resultados de busqueda lego
    state.kwordPieza = kword
  },
}

export default matutaions
