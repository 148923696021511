<template>
  <div class="card-full">
    <img 
      :src="imagen" 
      :alt="titulo"
      @error="setAltImg"
      class="card-full__img"
    >
    <div class="card-full__tipos">
      <span class="card-full__tipos__span">
        {{ horas }} horas
      </span>
      <span>-</span>
      <span class="card-full__tipos__span"> 
       {{ lenguaje }} 
      </span>
    </div>
    <router-link
      :to="link" class="card-full__title">
      {{ titulo }}
    </router-link>
    <p v-if="precio > 0" class="card-full__price">
      $ {{ precio }}
    </p>
    <p v-else class="card-full__price">
      GRATIS
    </p>
    <router-link
      :to="link"
      class="card-full__action">
      Mas sobre el curso
    </router-link>
  </div>
</template>

<script>
import notImg from '@/assets/001.png'
export default {
  name: 'VCardFull',
  props: {
    imagen: String,
    horas: Number,
    lenguaje: String,
    titulo: String,
    precio: Number,
    link: Object
  },
  methods: {
    setAltImg(event) { 
      event.target.src = notImg
    }, 
  }
}
</script>

<style lang="scss" scoped>
  .card-full {
    background-color: $n-color-white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    margin-bottom: 1em;
    @include hasta(1000px) {
      padding: 5px;
    }
    &__img {
      width: 100%;
      height: 200px;
      border-radius: 10px;
      margin-bottom: 10px;
      @include hasta(1000px) {
        height: 150px;
      }
    }
    &__tipos {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      &__span {
        font-family: $n-ff-3;
        margin: 3px;
        font-size: 12px;
        color: $n-color3;
      }
    }
    &__title {
      display: block;
      font-size: 16px;
      font-weight: bold;
      font-family: $n-ff-1;
      text-align: left;
      text-transform: capitalize;
      height: 50px;
      overflow: hidden;
      color: $n-color2;
      margin-bottom: 5px;
      @include hasta(1000px) {
        font-size: 14px;
        height: 40px;
      }
      &:hover {
        color: $n-color1;
      }
    }
    &__price {
      font-size: 24px;
      font-weight: bold;
      font-family: $n-ff-4;
      color: $n-color5;
    }
    &__action {
      display: none;
      widows: 100%;
      text-align: center;
      font-family: $n-ff-1;
      padding: 7px 1em 7px 1em;
      border-radius: 15px;
      background-color: $n-color5;
      color: $n-color-white;
      font-size: 14px;
      font-weight: bold;
      &:hover {
        color: $n-color-white;
      }
      @include hasta(1000px) {
        display: block;
        font-size: 12px;
        padding: 7px 11px 7px 11px;
      }
    }
    &:hover > a {
      display: block;
      display: block;
    }
  }
</style>