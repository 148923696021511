<template>
  <div 
    class="card-post"
    :style="{ borderLeft: '2px solid' + color }">
    <img 
      class="card-post__img"
      :src="imagen" 
      :alt="tag"
      @error="setAltImg">
    
    <div class="card-post__body">
      <div 
        :to="link"
        class="card-post__body__left">
        <router-link 
          :to="link" 
          class="card-post__body__left__text" v-html="contenido">
        </router-link>
        <p class="card-post__body__left__date">
          {{ formatDate(fecha) }}
        </p>
        <a 
          v-if="method" 
          @click="method(element)"
          class="card-post__body__left__action">{{ actionMethod }}</a>
      </div>
      <div class="card-post__body__right">
        <router-link
          :to="link"
          class="card-post__body__right__link" 
          :style="{ background: color }">
          ver respuestas
        </router-link>
        <div class="card-post__body__right__tags">
          <span class="card-post__body__right__tags__span">
            {{ respuestas }} R
          </span>
          <span class="card-post__body__right__tags__span">
            {{ tag }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import notImg from '@/assets/001.png'
  export default {
    name: 'VCardPost',
    props: {
      color: String,
      contenido: String,
      fecha: String,
      tag: String,
      link: {
        type: Object,
        default: function () {
          return {name: 'home', params: {}}
        }
      },
      imagen: String,
      respuestas: {
        type: Number,
        default: 0,
      },
      method: {type: Function},
      actionMethod: String,
      element: Object,
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
      formatDate: function (value) {
        return this.$moment(value).fromNow();
      },
    }
  }
</script>

<style lang="scss" scoped>
  .card-post {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 1em;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    width: 100%;
    &__img {
      display: block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin-right: 10px;
      @include hasta(1000px) {
        display: none;
      }
    }
    &__body {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      &__left {
        margin-right: 10px;
        width: 80%;
        &__text {
          display: block;
          font-family: $n-ff-4;
          font-size: 14px;
          color: $n-color2;
          height: 90px;
          overflow: hidden;
        }
        &__date {
          margin-bottom: 0px;
          font-family: $n-ff-3;
          font-size: 12px;
          color: $n-color3;
        }
        &__action {
          font-family: $n-ff-1;
          color: $n-color5;
        }
      }
      &__right {
        width: 250px;
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        @include hasta(1000px) {
          display: none;
        }
        &__link {
          color: $n-color-white;
          font-size: 12px;
          font-family: $n-ff-4;
          padding: 4px 10px 4px 10px;
          border-radius: 8px;
          &:hover {
            color: $n-color2;
          }
        }
        &__tags {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          &__span {
            font-size: 11px;
            font-family: $n-ff-4;
            color: $n-color3;
          }
        }
      }
    }
  }
</style>