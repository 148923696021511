// services for user api
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import global settings
import baseConf from '@/Base'

Vue.use(VueAxios, axios)

// instance for atentication
var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 12000
})

// var header = {
//   headers: {
//     'Authorization': 'Token ' + localStorage.getItem('token')
//   }
// }

const apiCursos = {
  listMainCursos: function () {
    return instance.get('/api/cursos-principales/')
  },
  cursosPopulares: function (filtro) {
    return instance.get('/api/cursos-populares/?orden='+filtro)
  },
  getCurso: function (uniqueName) {
    return instance.get('/api/cursos/detail/'+ uniqueName + '/')
  }
}

export default apiCursos