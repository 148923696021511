<template>
  <div class="grid-x grid-margin-x investigacion">
    <div class="cell investigacion__title">
      <h3 class="investigacion__title__h3">
        {{ titulo }}
      </h3>
      <a 
        class="investigacion__title__a"
        :href="link">
        <span>...</span>
      </a>
    </div>

    <div class="cell grid-x grid-margin-x">
      <div 
        class="cell small-6 medium-3 large-2"
        v-for="blog in listaBlogs" :key="blog.id">
          <v-card-image-icom 
            :imagen="blog.image"
            :titulo="blog.title"
            :link="{name: 'blog-detail', params: {slug: blog.slug}}"
            link-externo="#"
            :fecha="formatDate(blog.created)"
          />
      </div>
    </div>
  </div>
</template>

<script>
//api
import apiBlog from '@/api/blog'
//
import VCardImageIcom from '@/components/base/VCardImageIcom'

export default {
  name: 'ListaVideosInvestigacion',
  props: {
    titulo: String,
    link: {
      type: String,
      default: '#'
    }
  },
  data() {
    return {
      listaBlogs: []
    }
  },
  components: {
    VCardImageIcom
  },
  methods: {
    formatDate: function (value) {
      return this.$moment(value).fromNow();
    },
    listarBlogsVideo: function () {
      apiBlog.listaBlogTipo('0').then(
        (response) => {
          this.listaBlogs = response.data.results
        }
      )
    }
  },
  beforeMount() {
    this.listarBlogsVideo()
  }
}
</script>

<style lang="scss" scoped>

.investigacion {
  margin-top: 1em;
  margin-bottom: 1em;
}
.investigacion__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  &__h3 {
    font-family: $n-ff-5;
    font-size: 24px;
    font-weight: bold;
    color: $n-color2;
  }
  &__a {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    width: 60px;
    color: $n-ff-4;
  }
}

</style>