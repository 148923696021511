<template>
  <div class="card-image-icon">
    <div class="card-image-icon__body">
      <img 
        :src="imagen" 
        :alt="titulo"
        @error="setAltImg"
        class="card-image-icon__body__img"
      >
      <play-circle 
        class="card-image-icon__body__icon"
        :size="30"
      />
    </div>
    <div class="card-image-icon__footer">
      <router-link 
        :to="link"
        class="card-image-icon__footer__title">
          {{ titulo }}
      </router-link>
      <span class="card-image-icon__footer__span">
        {{ fecha }}
      </span>
    </div>

  </div>
</template>

<script>
  import notImg from '@/assets/001.png'
  export default {
    name: 'VCardImageIcom',
    props: {
      imagen: String,
      titulo: String,
      link: Object,
      linkExterno: String,
      fecha: String
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    },
  }
</script>

<style lang="scss" scoped>
  .card-image-icon {
    border-radius: 10px;
    display: block;
    background-color: $n-color-white;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    margin-bottom: 1em;
    &__body {
      position: relative;
      display: block;
      width: 100%;
      height: 100px;
      color: $n-color4;
      &__img {
        width: 100%;
        height: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      &__icon {
        position: absolute;
        right: 5px;
        bottom: -20px;
      }
    }
    &__footer {
      padding: 10px;
      display: flex;
      flex-direction: column;
      &__title {
        font-size: 14px;
        font-family: $n-ff-5;
        color: $n-color2;
        font-weight: bold;
        height: 50px;
        overflow: hidden;
      }
      &__span {
        font-size: 12px;
        font-family: $n-ff-3;
        color: $n-color3;
        margin-top: 10px;
      }
    }
  }
</style>