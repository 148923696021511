<template>
  <div v-if="screen > 1000" class="menu-foro">
    <h3 class="menu-foro__title">Filtrar por Cursos</h3>
    <ul class="menu-foro__ul">
      <li 
        class="menu-foro__ul__li"
        v-for="curso in cursos"
        :key="curso.id" >
        <a @click="seleccionarCurso(curso.id)" class="menu-foro__ul__li__a">
          <play-circle 
            class="menu-foro__ul__li__a__icon"
            :size="20"
          />
          <span 
            v-if="curso.id === curso_question" 
            class="menu-foro__ul__li__a__span"
            :style="{textDecoration: 'underline' + ' ' + curso.color}">
              {{ curso.title }}
          </span>
          <span 
            v-else 
            class="menu-foro__ul__li__a__span">
              {{ curso.title }}
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
  // vuex
  import { mapActions, mapGetters } from 'vuex'
  // api
  import apiCursos from '@/api/cursos'

  export default {
    name: 'TheMenuForo',
    data() {
      return {
        cursos: []
      }
    },
    props: {
      screen: Number
    },
    computed: {
      ...mapGetters({
        curso_question: 'curso_question'
      })
    },
    methods: {
      ...mapActions ({
        SetCursoQuestion: 'SetCursoQuestion',
      }),
      listaCursos: function () {
        apiCursos.cursosPopulares(this.$route.params.filtro).then(
          (response) => {
            this.cursos = response.data.results
          }
        )
      },
      seleccionarCurso: function (id_curso) {
        this.SetCursoQuestion(id_curso)
      }
    },
    beforeMount() {
      this.listaCursos()
    },
  }

</script>

<style lang="scss" scoped>
  .menu-foro {
    padding: 10px;
    &__title {
      font-family: $n-ff-2;
      font-size: 16px;
      color: $n-color1;
      font-weight: bold;
    }
    &__ul {
      display: block;
      list-style: none;
      margin: 0px;
      padding: 0px;
      &__li {
        display: block;
        margin: 5px;
        &__a {
          display: flex;
          color: $n-color2;
          align-items: center;
          padding: 7px;
          font-family: $n-ff-4;
          font-size: 14px;
          border-radius: 10px;
          &__icon {
            margin-right: 10px;
            margin-bottom: 0px;
          }
          &__span {
            margin-bottom: 7px;
          }
          &:hover {
            background: $n-color1;
            color: $n-color-white;
          }
        }
      }
    }
  }
</style>

