<template>
  <div class="banner-foro">
    <img 
      class="banner-foro__img"
      src="../../assets/001.png" 
      alt="Foro neunapp">
    <form
      class="banner-foro__copy"
      method="get"
      @submit.prevent="buscarPorKword()">
        <h3 class="banner-foro__copy__title">
          Buscar un tema o una pregunta, sobre alguno de los cursos de Neunapp
        </h3>
        <div class="banner-foro__copy__search">
          <input 
            type="text"
            placeholder="Buscar..."
            class="banner-foro__copy__search__input"
            v-model="kword">
          <button 
            class="banner-foro__copy__search__btn"
            type="submit">
            Buscar
          </button>
        </div>
        <div class="banner-foro__filter">
          <a 
            class="banner-foro__filter__btn"
            @click="limpiarFiltros()">Limpiar Filtros</a>
          <a
            class="banner-foro__filter__btn resaltado"
            @click="abrirPanelQuestion()">Enviar Preguntar</a>
        </div>
    </form>

    <foro-add-pregunta 
      v-if="showAddQuestion"
      :method="cerrarPanel"
     />

    <app-msj-alert
        v-if="showAddQuestion && !isLoggedIn"
        mensaje="Debes de estar logueado para enviar preguntas"
        actionMain="De Acuerdo"
        actionClose="Cancelar"
        :link="{name: 'login'}"
        :method="closeAlert"
    />

  </div>
</template>

<script>

  import { mapActions, mapGetters } from 'vuex'

  import ForoAddPregunta from '@/components/foro/ForoAddPregunta'
  import AppMsjAlert from '@/components/apps/AppMsjAlert'

  export default {
    name: 'TheBannerForo',
    data() {
      return {
        kword: '',
        showAddQuestion: false
      }
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      })
    },
    components: {
      ForoAddPregunta,
      AppMsjAlert
    },
    methods: {
      ...mapActions({
        SetKwordQuestion: 'SetKwordQuestion',
        SetCursoQuestion: 'SetCursoQuestion'
      }),
      buscarPorKword: function () {
        this.SetKwordQuestion(this.kword)
      },
      limpiarFiltros: function () {
        this.SetKwordQuestion('')
        this.SetCursoQuestion(-1)
      },
      abrirPanelQuestion: function () {
        this.showAddQuestion = true
      },
      cerrarPanel: function () {
        this.showAddQuestion = false
      },
      closeAlert: function () {
      this.showAddQuestion = false
    }
    },
  }
</script>

<style lang="scss" scoped>
  .banner-foro {
    width: 100%;
    height: 300px;
    position: relative;
    @include hasta(1000px) {
      height: 200px;
    }
    @include desde (1904px) {
      height: 400px;
    }
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    &__copy {
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      padding: 4em;
      text-align: center;
      @include hasta(1000px) {
        padding: 2em;
      }
      @include desde (1904px) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
      }
      &__title {
        font-size: 20px;
        font-weight: bold;
        color: $n-color-white;
        font-family: $n-ff-1;
        @include hasta(1000px) {
          font-size: 12px;
        }
      }
      &__search {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1em 2em 1em 2em;
        @include hasta(1000px) {
          padding: 10px;
        }
        &__input {
          height: 35px;
          border-radius: 10px;
          margin: 5px;
          padding-left: 1em;
          font-family: $n-ff-4;
          &:focus {
            border-color: $n-color1;
          }
          @include desde (1904px) {
            width: 500px;
          }
        }
        &__btn {
          background: $n-color5;
          height: 35px;
          padding-left: 20px;
          padding-right: 20px;
          border-radius: 10px;
          color: $n-color-white;
          font-size: 14px;
          font-family: $n-ff-4;
          &:hover {
            cursor: pointer;
            background: $n-color4;
          }
          @include hasta(1000px) {
            padding-left: 12px;
            padding-right: 12px;
          }
        }
      }
    }
    &__filter {
      display: flex;
      justify-content: center;
      align-items: center;
      &__btn {
        background: $n-color3;
        color: $n-color-white;
        font-family: $n-ff-1;
        padding: 6px 15px;
        border-radius: 10px;
        font-size: 14px;
        margin: 10px;
        &.resaltado {
          background: $n-color1;
        }
        &:hover {
          background: $n-color2;
          color: $n-color-white;
        }
      }
    }
  }
</style>