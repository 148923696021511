// importamos vistas locales
import BlogPage from '@/views/blog/BlogPage'
import BlogDetailPage from '@/views/blog/BlogDetailPage'

const blogRutes = [
  {
    path: '/contenido',
    name: 'blog',
    component: BlogPage,
  },
  {
    path: '/contenido/:slug',
    name: 'blog-detail',
    component: BlogDetailPage,
  },
]

export default blogRutes