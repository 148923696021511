// services for user api
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import global settings
import baseConf from '@/Base'

Vue.use(VueAxios, axios)

// instance for atentication
var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 12000
})

const apiLego = {
  listarCategorias: function () {
    return instance.get('/api/lego/lista-categorias/')
  },
  buscarPiezasLego: function (categoria, kword) {
    return instance.get('/api/lego/buscar/?category=' + categoria + '&kword=' + kword)
  },
  retriveLego: function (slug) {
    return instance.get('/api/lego/detail/' + slug + '/')
  },
}

export default apiLego