<template>
  <div class="msj-soporte">
    <router-link
      class="msj-soporte__link" 
      :to="link">
        {{ titulo }}
    </router-link>
  </div>
</template>

<script>
  export default {
    name: 'AppMsjSoporte',
    props: {
      titulo: String,
      link: Object
    }
  }
</script>

<style lang="scss" scoped>
  .msj-soporte {
    display: block;
    padding: 1em;
    border-radius: 10px;
    background: $n-color1;
    text-align: center;
    margin: 1em;
    &__link {
      color: $n-color-white;
      font-family: $n-ff-4;
      font-weight: bold;
      &:hover {
        color: $n-color2;
      }
    }
  }
</style>