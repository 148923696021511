<template>
  <div>
    <lego-user />
  </div>
</template>

<script>

  import LegoUser from '@/components/users/LegoUser'

  export default {
    name: 'LegoUserPage',
    components: {
      LegoUser
    }
  }
</script>

<style lang="scss" scoped>

</style>