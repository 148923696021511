<template>
  <div class="card-small">
    <div 
      class="card-small__content"
      :style="{ background: color }">
      <img 
        :src="image" 
        :alt="titulo"
        @error="setAltImg"
        class="card-small__content__img">
      <router-link :to="link"
        class="card-small__content__link">
        <p class="card-small__content__link__p">
          {{ titulo }}
          <play-circle-outline 
            :size="15"
            class="card-small__content__link__icon"></play-circle-outline>
        </p>
        <span class="card-small__content__span"> {{ text }} </span>
      </router-link>
    </div>
  </div>
</template>

<script>
  import notImg from '@/assets/001.png'
  export default {
    name: 'VCardSmall',
    props: {
      image: String,
      titulo: String,
      text: String,
      link: Object,
      color: String,
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    },
  }
</script>

<style lang="scss" scoped>
  .card-small {
    margin-bottom: 1.3em;
    margin-top: 1em;
    height: 190px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    border-radius: 10px;
    box-shadow: 0px 10px 2px -8px rgba(192,192,196,1);
    @include desde (1904px) {
        height: 210px;
    }
  }

  .card-small__content {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 130px;
    padding-bottom: 5px;
    border-top-right-radius: 5px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    @include desde (1904px) {
        height: 160px;
    }
    &__img {
      position: absolute;
      top: -65px;
      width: 85%;
      height: 120px;
      border-radius: 5px;
      @include desde (1904px) {
        height: 150px;
      }
    }
    &__link {
      width: 85%;
      &__p {
        color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 35px;
        overflow: hidden;
        font-weight: bold;
        font-size: 13px;
        font-family: $n-ff-1;
        margin: 0px;
      }
    }
    &__span {
      font-size: 12px;
      font-family: $n-ff-3;
      color: $n-color-white;
    }
  }
</style>