// importamos vistas locales
import Index from '@/views/Index'
import PaginaNoEncontrada from '@/views/PaginaNoEncontrada'
import HomePage from '@/views/home/HomePage'
// local rutes
import foroRutes from './foro'
import cursosRutes from './cursos'
import blogRutes from './blog'
import usersRutes from './users'
import legoRutes from './lego'

const homeRutes = [
  {
    path: '/',
    component: Index,
    children: [
      {
        path: '',
        name: 'home',
        component: HomePage,
      }      
    ].concat(foroRutes, cursosRutes, blogRutes, usersRutes, legoRutes)
  },
  {
    path: '*',
    component: PaginaNoEncontrada
  }
]

export default homeRutes