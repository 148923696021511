<template>
  <div>
    <div v-if="blog" class="grid-x grid-margin-x align-center detail-blog">
      <div v-if="blog.tipo !== '0'" class="cell small-12 large-8 grid-x grid-margin-x">
        <div v-if="blog" class="cell">
          <h3 class="detail-blog-title" :style="{color: blog.color}">
            {{ blog.title }}
          </h3>
        </div>
        <div v-if="blog" class="cell">
          <v-blog-content 
            :image="blog.image"
            :autor="blog.author"
            :fecha="formatDate(blog.created)"
            :content="blog.content"
          />
        </div>
      </div>
      <div v-else class="cell small-12 large-8 grid-x grid-margin-x detail-blog">
        <v-card-blog 
          :titulo="blog.title"
          :resumen="blog.sub_title"
          :iconsText="blog.tag"
          :video="blog.video"
          :image="blog.image"
          :texto="blog.content"
          :fecha="'Publicado: '+formatDate(blog.created)"
        />
      </div>
      
      <!-- seccion de curso destacados -->
      <div class="cell small-12 large-2">
        <h4 class="detail-blog__right">Cursos Destacados:</h4>
        <main-cursos 
          nameClass="cell small-6 large-12"
        />
      </div>
    </div>
  </div>
</template>

<script>
  // api
  import apiBlog from '@/api/blog'
  //
  import VBlogContent from '@/components/base/VBlogContent'
  import VCardBlog from '@/components/base/VCardBlog'
  import MainCursos from '@/components/cursos/MainCursos'

  export default {
    name: 'TheDetailBlog',
    data() {
      return {
        blog: null
      }
    },
    components: {
      VBlogContent,
      VCardBlog,
      MainCursos
    },
    methods: {
      formatDate: function (value) {
        return String(this.$moment(value).fromNow());
      },
      cargaBlog: function () {
        apiBlog.listMainCursos(this.$route.params.slug).then(
          (response) => {
            this.blog = response.data
          }
        )
      }
    },
    beforeMount() {
      this.cargaBlog()
    }
  }
</script>

<style lang="scss" scoped>
  .detail-blog {
    display: flex;
    align-items: flex-start;
  }
  .detail-blog-title {
    border: 1px solid;
    padding: 10px 16px 10px 16px;
    background: $n-color-white;
    margin-bottom: 1em;
    text-align: center;
    font-family: $n-ff-1;
    border-radius: 10px;
    font-size: 24px;
    font-weight: bold;
  }

  .detail-blog__right {
    display: block;
    font-size: 14px;
    font-family: $n-ff-2;
    margin-bottom: 1em;
    color: $n-color3;
  }
</style>