<template>
  <div class="cell grid-x grid-margin-x posts">
    <div
      class="cell small-12"
      v-for="resultado in listaResultados"
      :key="resultado.id">
      <v-card-post 
        :color="resultado.question.curso.color"
        :imagen="resultado.question.curso.image"
        :contenido="convertirOpsHtml(resultado.content_json)"
        :fecha="resultado.created.toString()"
        :tag="resultado.question.curso.lenguaje"
        :respuestas="resultado.question.num_like"
        :link="{name:'pregunta-detail', params: {slug: resultado.question.slug}}"
        :method="abrirEditar"
        action-method="Editar"
        :element="resultado"
      />
    </div>
    <a 
      v-if="moreResults"
      @click="listarRespuestas()"
      class="more-answers__action">
      Cargar más respuestas
    </a>

    <answer-view-user
      v-if="showEditar"
      :method="cerrarEditar"
      :answer="objectSelect"
     />

    <app-loading v-if="loading"/>
  </div>
</template>

<script>
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
  //
  import apiPregunta from '@/api/pregunta'
  //
  import VCardPost from '@/components/base/VCardPost'
   //
  import AppLoading from '@/components/apps/AppLoading'
  //
  import AnswerViewUser from '@/components/users/AnswerViewUser'

  export default {
    name: 'AnswerUsers',
    data() {
      return {
        listaResultados: [],
        loading: false,
        moreResults: true,
        page: 0,
        contenido: '',
        objectSelect: null,
        showEditar: false
      }
    },
    components: {
      VCardPost,
      AppLoading,
      AnswerViewUser
    },
    methods: {
      listarRespuestas: function () {
        if (this.moreResults) {
          this.loading = true
          this.page = this.page + 1
          apiPregunta.respuestasPorUsuario(this.page).then(
            (response) => {
              this.listaResultados = this.listaResultados.concat(response.data.results)
              this.loading = false
              //
              if (response.data.next) {
                this.moreResults = true
              } else {
                this.moreResults = false
              }
            }
          ).catch(() => {
            this.loading = false
          })
        }
      },
      convertirOpsHtml: function (deltaOps) {
        let delta = JSON.parse(deltaOps)
        let cfg = {}
        let converter = new QuillDeltaToHtmlConverter(delta, cfg)
        return converter.convert()
      },
      abrirEditar: function (elemento) {
        console.log('abirendo ver pregunta')
        this.showEditar = true
        this.objectSelect = elemento
      },
      cerrarEditar: function () {
        this.showEditar = false
        this.objectSelect = null
      }
    },
    beforeMount() {
      this.listarRespuestas()
    },
  }
</script>

<style lang="scss" scoped>
  .more-answers {
    padding: 15px;
    border-radius: 20px;
    background: $n-color-white;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    &__action {
      font-size: 14px;
      font-family: $n-ff-2;
      color: $n-color2;
      display: block;
      width: 100%;
      text-align: center;
      text-decoration: underline;
    }
  }
</style>