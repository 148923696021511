<template>
  <div class="grid-x grid-margin-x align-center">
    <div v-if="showMetodos" class="cell small-12 large-8">
      <payment-method />
    </div>
    <div class="cell small-12 large-4">
      <payment-process />
    </div>
  </div>
</template>

<script>
  // vuex
  import {mapGetters} from 'vuex'
  // components
  import PaymentMethod from '@/components/venta/PaymentMethod.vue'
  import PaymentProcess from '@/components/venta/PaymentProcess.vue'

  export default {
    name: 'LegoPayment',
    components: {
      PaymentMethod,
      PaymentProcess
    },
    computed: {
      ...mapGetters({
        venta: 'venta'
      }),
      showMetodos: function () {
        if (this.venta) {
          if (this.venta.amount > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      }
    },
    beforeMount() {
      
    }
  }
</script>

<style lang="scss" scoped>

</style>