<template>
  <div class="video-yb">
    <iframe
      class="video-yb__frame"
      :src="srcVideo" 
      frameborder="0"
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>
  export default {
    name: 'VVideoYoutube',
    props: {
      srcVideo: String
    }
  }
</script>

<style lang="scss" scoped>
  .video-yb {
    display: block;
    margin-bottom: 1em;
    &__frame {
      width: 100%;
      height: 300px;
      @include desde (1000px) {
        height: 600px;
      }
      @include desde(1904px) {
          height: 650px;
      }
    }
  }
</style>