<template>
  <div>
    <the-header></the-header>
    <!-- // contenido en la pagina -->
    <div 
      class="grid-container fluid">
        <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
</template>

<script>

import TheHeader from '@/components/includes/TheHeader'

export default {
  name: 'Index',
  components: {
    TheHeader
  }
}
</script>