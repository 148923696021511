<template>
  <div>
    <the-banner-home />
    <lista-cursos
      titulo="Ultimos Cursos Publicados"
      link="#" 
    />
    <lista-videos-investigacion
      titulo="Nuevo Contenido Publicado"
      link="https://www.youtube.com/channel/UCcZoaMlaUXf_0KFXrEWt2Ww/videos?view_as=subscriber"
     />
     <lista-blogs-mas-visitados 
      titulo="Articulos más visitados"
     />
  </div>
</template>

<script>

import TheBannerHome from '@/components/home/TheBannerHome'
import ListaCursos from '@/components/cursos/ListaCursos'
import ListaVideosInvestigacion from '@/components/investigacion/ListaVideosInvestigacion'
import ListaBlogsMasVisitados from '@/components/blogs/ListaBlogsMasVisitados'

export default {
  name: 'HomePage',
  components: {
    TheBannerHome,
    ListaCursos,
    ListaVideosInvestigacion,
    ListaBlogsMasVisitados
  }
}
</script>