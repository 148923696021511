// importamos los tipos de mutacion

import * as types from './mutation-types'

const matutaions = {
  [types.SET_KWORD_QUESTION] (state, kword) {
    // operacion pendiente de repuesta
    state.kword_question = kword
  },
  [types.SET_CURSO_QUESTION] (state, id_curso) {
    // operacion pendiente de repuesta
    state.curso_question = id_curso
  },
}

export default matutaions
