<template>
  <div class="icon-text-inline">
    <component 
      class="icon-text-inline__icon"
      :size="20" 
      :fillColor="color"
      v-bind:is="iconComponent"
    ></component>
    <span class="icon-text-inline__text"> {{ texto }} </span>
  </div>
</template>

<script>
  export default {
    name: 'VIconTextInline',
    props: {
      texto: String,
      iconComponent: String,
      color: {
        type: String,
        default: '#1e272e'
      }
    }
  }
</script>

<style lang="scss" scoped>
  .icon-text-inline {
    display: flex;
    justify-content: center;
    align-items: center;
    &__icon {
      margin: None;
      padding: None;
      margin-top: 10px;
    }
    &__text {
      margin-right: 20px;
      margin-left: 10px;
      margin-bottom: 5px;
      margin-top: 10px;
      font-family: $n-ff-5;
      font-size: 14px;
    }
  }
</style>