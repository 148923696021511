// importamos vistas locales
import LegoPage from '@/views/lego/LegoPage.vue'
//import LegoAux from '@/views/lego/LegoAux'
import LegoGalery from '@/views/lego/LegoGalery.vue'
import LegoDetail from '@/views/lego/LegoDetail.vue'
import LegoPayment from '@/views/lego/LegoPayment.vue'

const legoRutes = [
  {
    path: '/lego',
    component: LegoPage,
    children: [
      {
        path: ':categoria',
        name: 'lego',
        component: LegoGalery,
      },
      {
        path: 'detail/:slugLego',
        name: 'lego-detail',
        component: LegoDetail,
      },
      {
        path: 'payment/generar/:slugLego',
        name: 'lego-venta',
        component: LegoPayment,
      },
    ]
  }
]

export default legoRutes