<template>
  <div class="grid-x grid-margin-x">
    <div class="cell">
      <!-- aqui va la pregunta extendidad -->
      <DetallePregunta />
    </div>
    <div class="cell">
      <!-- aqui va la en orden de destacadas -->
      <div class="foro-respuestas-head">
        <h4 class="foro-respuestas-head__title">Respuestas:</h4>
        <a 
          @click="showAddRespuesta = !showAddRespuesta"
          class="foro-respuestas-head__link">
          Agregar mi Repuesta
        </a>
      </div>
      <foro-add-respuesta 
        v-if="showAddRespuesta && isLoggedIn"
        :method="closeAlert"/>
      <app-msj-alert
        v-if="showAddRespuesta && !isLoggedIn"
        mensaje="Debes de estar logueado para poder acceder"
        actionMain="De Acuerdo"
        actionClose="Cancelar"
        :link="{name: 'login'}"
        :method="closeAlert"
      />
      <foro-respuestas-pregunta 
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DetallePregunta from '@/components/foro/DetallePregunta'
import ForoRespuestasPregunta from '@/components/foro/ForoRespuestasPregunta'
import ForoAddRespuesta from '@/components/foro/ForoAddRespuesta'
import AppMsjAlert from '@/components/apps/AppMsjAlert'

export default {
  name: 'ForoRespuestas',
  data() {
    return {
      showAddRespuesta: false
    }
  },
  components: {
    DetallePregunta,
    ForoRespuestasPregunta,
    ForoAddRespuesta,
    AppMsjAlert
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn',
    })
  },
  methods: {
    closeAlert: function () {
      this.showAddRespuesta = false
    }
  },
}
</script>

<style lang="scss" scoped>
  .foro-respuestas-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-left: 5px;
    padding-right: 5px;
    &__title {
      font-size: 12px;
      font-weight: bold;
      font-family: $n-ff-3;
      color: $n-color4;
    }
    &__link {
      font-size: 13px;
      font-family: $n-ff-1;
      color: $n-color3;
      border: 1px solid;
      padding: 3px 15px 3px 15px;
      border-radius: 10px;
    }
  }
</style>