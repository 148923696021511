<template>
  <div class="msj-alert">
    <div class="msj-alert__content">
      <p class="msj-alert__content__text">{{ mensaje }}</p>
      <div class="msj-alert__content__ctrls">
        <router-link 
          :to="link"
          class="msj-alert__content__btn">
            {{ actionMain }}
        </router-link>
        <a 
          @click="method()"
          class="msj-alert__content__btn alert">
            {{ actionClose }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppMsjAlert',
    props: {
      mensaje: String,
      actionMain: String,
      actionClose: String,
      link: Object,
      method: {type: Function}
    }
  }
</script>

<style lang="scss" scoped>
  .msj-alert {
    position: fixed;
    top: 0px;
    left: 0px;
    border: 1px solid;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 52, 52, 0.5);
    &__content {
      width: 300px;
      background: $n-color-white;
      padding: 10px;
      border-radius: 10px;
      text-align: center;
      &__text {
        width: 100%;
        text-align: center;
        font-family: $n-ff-2;
        font-size: 15px;
        color: $n-color1;
        font-weight: bold;
      }
      &__ctrls {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &__btn {
        display: block;
        margin: 5px;
        font-size: 14px;
        font-family: $n-ff-1;
        background: $n-color5;
        color: $n-color-white;
        padding: 5px 20px;
        border-radius: 5px;
        width: 120px;
        &:hover {
          color: $n-color-white;
          opacity: 0.6;
        }
        &.alert {
          background: $n-color2;
        }
      }
    }
  }
</style>