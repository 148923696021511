<template>
  <div v-if="curso" class="cell action-curso">
    <p v-if="curso.price > 0" class="action-curso__price">
      <span class="action-curso__price__amount">$/ {{ curso.price }}</span>
      <span class="action-curso__price__text">Un solo pago</span>
    </p>
    <p v-else class="action-curso__price">
      <span class="action-curso__price__amount">GRATIS</span>
    </p>
    <v-button-action 
      icono="cart"
      texto="Ir a Comprar"
      color="#8e44ad"
      :link="curso.curso_url"
    />
    <a 
      class="action-curso__temario"
      href="#">Ir a ver todo el temario</a>

    <div class="action-curso__items">
      <v-icon-text-inline 
        v-for="caracteristica in curso.features"
        :key="caracteristica.id"
        :texto="caracteristica.name"
        :iconComponent="caracteristica.icon"
      />
    </div>
  </div>
</template>

<script>

  import VButtonAction from '@/components/base/VButtonAction'
  import VIconTextInline from '@/components/base/VIconTextInline'

  export default {
    name: 'ActionCurso',
    props: {
      curso: {
        type: Object,
        default: () => {return null}
      }
    },
    components: {
      VButtonAction,
      VIconTextInline
    }
  }
</script>

<style lang="scss" scoped>
  .action-curso {
    display: block;
    width: 100%;
    background: white;
    padding: 10px;
    &__price {
      display: flex;
      align-items: center;
      margin-bottom: 1em;
      &__amount {
        font-size: 25px;
        margin-right: 20px;
        font-weight: bold;
        color: $n-color5;
        font-family: $n-ff-2;
      }
      &__text {
        font-size: 12px;
        color: $n-color1;
        font-weight: bold;
      }
    }
    &__temario {
      display: block;
      margin: 10px;
      font-family: $n-ff-1;
      color: $n-color4;
    }
    &__items {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding-left: 10px;
    }
  }
</style>