<template>
  <a 
    class="btn-action"
    :href="link"
    :style="{background: color}">
    <component 
      class="btn-action__icon"
      :size="40" 
      fillColor="white"
      v-bind:is="icono"
    ></component>
    <span class="btn-action__texto"> {{ texto }} </span>
  </a>
</template>

<script>
  export default {
    name: 'VButtonAction',
    props: {
      icono: String,
      texto: String,
      link: String,
      color: String
    }
  }
</script>

<style lang="scss" scoped>
  .btn-action {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 65px;
    border-radius: 5px;
    &__texto {
      margin-left: 1em;
      color: white;
      font-family: $n-ff-1;
      font-weight: bold;
    }
  }
</style>