<template>
  <div>
    <questions-user />
  </div>
</template>

<script>

  import QuestionsUser from '@/components/users/QuestionsUser'

  export default {
    name: 'QuestionUsersPage',
    components: {
      QuestionsUser
    }
  }
</script>

<style lang="scss" scoped>

</style>