<template>
  <div v-if="banner" class="grid-x grid-margin-x">
    <div class="cell small-12 large-8">
      <div class="banner-portada">
        <img 
          :src="banner.entry_outstanding.image" 
          :alt="banner.entry_outstanding.title"
          class="banner-portada__img">
        
        <div class="banner-portada__copy">
          <p class="banner-portada__copy__text">
            {{ formatDate(banner.entry_outstanding.created) }}
          </p>
          <h1 class="banner-portada__copy__title">
            {{ banner.entry_outstanding.title }}
          </h1>
          <router-link
            class="banner-portada__copy__btn"
            :to="{ name: 'blog-detail', params: {slug: banner.entry_outstanding.slug}}">
              <span class="banner-portada__copy__btn__text">
                Ver mas
              </span>
              <send  :size="18" class="banner-portada__copy__btn__icon"></send>
          </router-link>
        </div>
      </div>
    </div>
    <div class="cell small-12 large-4 grid-x grid-margin-x">
      <div
        class="cell small-6 medium-3 large-6">
          <v-card-small  
            :image="banner.curso_one.image"
            :titulo="banner.curso_one.title"
            :link="{ 
              name: 'curso-detail', 
              params: {uniqueName: banner.curso_one.unique_name}}"
            :text="banner.curso_one.num_stundents + ' estudiantes'"
            :color="banner.curso_one.color"
          />
      </div>
      <div
        class="cell small-6 medium-3 large-6">
          <v-card-small  
            :image="banner.curso_two.image"
            :titulo="banner.curso_two.title"
            :link="{ 
              name: 'curso-detail', 
              params: {uniqueName: banner.curso_two.unique_name}}"
            :text="banner.curso_two.num_stundents + ' estudiantes'"
            :color="banner.curso_two.color"
          />
      </div>
      <div
        class="cell small-6 medium-3 large-6">
          <v-card-small  
            :image="banner.curso_three.image"
            :titulo="banner.curso_three.title"
            :link="{ 
              name: 'curso-detail', 
              params: {uniqueName: banner.curso_three.unique_name}}"
            :text="banner.curso_three.num_stundents + ' estudiantes'"
            :color="banner.curso_three.color"
          />
      </div>
      <div
        class="cell small-6 medium-3 large-6">
          <v-card-small  
            :image="banner.curso_four.image"
            :titulo="banner.curso_four.title"
            :link="{ 
              name: 'curso-detail', 
              params: {uniqueName: banner.curso_four.unique_name}}"
            :text="banner.curso_four.num_stundents + ' estudiantes'"
            :color="banner.curso_four.color"
          />
      </div>
    </div>
  </div>
  <div v-else>
    <app-loading-image v-if="loading" />
  </div>
</template>

<script>

// services
import apiHome from '@/api/home'

import VCardSmall from '@/components/base/VCardSmall'
import AppLoadingImage from '@/components/apps/AppLoadingImage'

export default {
  name: 'TheBannerHome',
  data() {
    return {
      banner: null,
      loading: false
    }
  },
  components: {
    VCardSmall,
    AppLoadingImage
  },
  methods: {
    cargaHome: function () {
      this.loading = true
      apiHome.getHome().then(
        (response) => {
          this.banner = response.data
          this.loading = false
        }
      ).catch(() => {
        this.loading = false
      })
    },
    formatDate: function (value) {
      return this.$moment(value).fromNow();
    }
  },
  beforeMount() {
    this.cargaHome()
  },
}
</script>

<style lang="scss" scoped>
  .banner-portada {
    position: relative;
    width: 100%;
    height: 400px;
    @include hasta (1024px) {
       height: 300px;
       margin-bottom: 2em;
    }
    @include desde (1904px) {
      height: 550px;
    }
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    &__copy {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2.5em;
      &__text {
        font-family: $n-ff-3;
        font-size: 14px;
        color: $n-color-white;
        margin-bottom: 10px;
        @include hasta (1024px) {
          font-size: 12px;
        }
      }
      &__title {
        font-size: 50px;
        font-weight: bold;
        color: $n-color-white;
        font-family: $n-ff-1;
        width: 50%;
        margin-bottom: 15px;
        max-height: 200px;
        overflow: hidden;
        @include hasta (1024px) {
          width: 70%;
          font-size: 30px;
        }
      }
      &__btn {
        width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $n-color-white;
        border: 1px solid;
        border-radius: 20px;
        padding: 10px;
        @include hasta (1024px) {
          width: 130px;
          padding: 5px;
          border-radius: 15px;
        }
        &:hover {
          background: $n-color2;
          color: $n-color-white;
        }
        &__text {
          font-family: $n-ff-1;
          font-weight: bold;
          margin-right: 15px;
        }
        &__icon {
          margin-top: 5px;
        }
      }
    }
  }
</style>