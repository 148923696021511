<template>
  <div class="grid-x grid-margin-x">
    <div class="cell login">
      <div class="login-content">
        <h3 class="login-content__title">
        <span>Bienvenido a</span> 
        <span class="login-color1"> Neunapp</span>
        </h3>
        <p class="login-content__text1">
          Con una cuenta en Neunapp podrás publicar preguntas, respuestas y acceder a código de pequeños complementos que construimos en Neunapp para toda la comunidad.   </p>
        <img
          class="login-content__img" 
          src="../../assets/developer.jpg" 
          alt="neunapp login"
        >
        <p class="login-content__text2">
          Forma parte de esta comunidad de desarrollo web con Javascript y Python.
        </p>
        <login-google />
      </div>
    </div>
  </div>
</template>

<script>

  // vuex
  import { mapGetters } from 'vuex'

  import LoginGoogle from '@/components/users/LoginGoogle.vue'

  export default {
    name: 'LoginPage',
    components: {
      LoginGoogle
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      })
    },
    beforeMount() {
      if (this.isLoggedIn) {
        this.$router.push({'name': 'user-profile'})
      } else {
        console.log(this.isLoggedIn)
      }
    },
  }
</script>

<style lang="scss" scoped>
  .login {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  .login-content {
    width: 100%;
    padding: 1em 10px 1em 10px;
    border-radius: 10px;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    text-align: center;
    @include desde (720px) {
      width: 400px;
      padding: 1em;
    }
    &__title {
      font-size: 14px;
      font-family: $n-ff-1;
      font-weight: bold;
      color: $n-color2;
      margin-bottom: 2em;
    }
    &__text1 {
      font-size: 14px;
      font-family: $n-ff-2;
      color: $n-color3;
    }
    &__img {
      width: 100%;
      max-height: 350px;
      margin-bottom: 10px;
      border-radius: 10px;
    }
    &__text2 {
      font-size: 12px;
      font-family: $n-ff-2;
      color: $n-color3;
    }
  }

  .login-color1 {
    color: $n-color4;
  }
</style>