<template>
  <div v-if="curso" class="grid-x grid-margin-x">
    <div class="cell small-12 large-9">
      <v-card-blog 
        :titulo="curso.title"
        :resumen="curso.sub_title"
        :iconsText="listaIconosText"
        :video="curso.video"
        :image="curso.image"
        :texto="curso.content"
        :fecha="formatDate(curso.created)"
      />
    </div>
    <div class="cell small-12 large-3">
      <ActionCurso 
        :curso="curso"
      />
    </div>
  </div>
  <div v-else>
    <p>cargando....</p>
  </div>
</template>

<script>
  // api
  import apiCurso from '@/api/cursos'
  //
  import VCardBlog from '@/components/base/VCardBlog'
  import ActionCurso from '@/components/cursos/ActionCurso'

  export default {
    name: 'DetailCurso',
    data() {
      return {
        curso: null,
      }
    },
    components: {
      VCardBlog,
      ActionCurso
    },
    computed: {
      listaIconosText: function () {
        return [
          {'id': 0, 'name': this.curso.num_stundents + ' Alumnos', 'icono': 'account-group'},
          {'id': 1, 'name': this.curso.hours + ' Horas', 'icono': 'clock-outline'},
        ]
      }
    },
    methods: {
      formatDate: function (value) {
        return String(this.$moment(value).fromNow());
      },
      cargaCurso: function () {
        apiCurso.getCurso(this.$route.params.uniqueName).then(
          (response) => {
            this.curso = response.data
          }
        )
      }
    },
    beforeMount() {
      this.cargaCurso()
    },
  }
</script>

<style lang="scss" scoped>

</style>