<template>
  <div class="pregunta">
    <h3 class="pregunta__title">Previsulizacion de una Pregunta</h3>
    <form 
      class="view-pregunta"
      method="POST"
      @submit.prevent="eliminarRespuesta()"
      >
      <div class= "view-pregunta__content" style="height: 250px;">
        <p v-html="convertirOpsHtml(answer.content_json)">

        </p>
      </div>
      <div class="view-pregunta__actions">
        <a
          class="view-pregunta__actions__btn"
          @click="method()">
          Salir
        </a>
        <button 
          class="view-pregunta__actions__btn delete"
          type="submit">
          Eliminar
        </button>
      </div>

      <app-loading v-if="loading" />
    </form>
  </div>
</template>

<script>
  //
  import apiPregunta from '@/api/pregunta'
  //
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
  // 
  import AppLoading from '@/components/apps/AppLoading'

  export default {
    name: 'AnswerViewUser',
    components: {
      AppLoading
    },
    data() {
      return {
        loading: false,
      }
    },
    props: {
      method: {type: Function},
      answer: Object,
    },
    methods: {
      convertirOpsHtml: function (deltaOps) {
        let delta = JSON.parse(deltaOps)
        let cfg = {}
        let converter = new QuillDeltaToHtmlConverter(delta, cfg)
        return converter.convert()
      },
      eliminarRespuesta: function () {
        this.loading = true
        apiPregunta.eliminarRespuestaId(this.answer.id).then(
          () => {
            this.loading = false
            this.method()
            this.$router.go()
          }
        ).catch (
          () => this.loading = false 
        )
      }
    }
  }
</script>

<style lang="scss" scoped>
  .pregunta {
    position: fixed;
    top: 0px;
    left: 0px;
    border: 1px solid;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 52, 52, 0.5);
    flex-direction: column;
    &__title {
      font-size: 12px;
      font-family: $n-ff-1;
      font-weight: bold;
      color: $n-color6;
      background: $n-color-white;
      padding: 3px 10px;
      text-transform: capitalize;
    }
  }
  .view-pregunta {
    background: $n-color-white;
    border-radius: 10px;
    padding: 1em;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    margin-bottom: 1em;
    @include hasta(720px) {
      width: 100%;
    }
    @include desde(720px) {
      width: 50%;
    }
    @include desde(1904px) {
      width: 40%;
    }
    &__content {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    &__actions {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-family: $n-ff-2;
      &__btn {
        background: $n-color4;
        padding: 5px 15px 5px 15px;
        margin-right: 10px;
        color: $n-color-white;
        &.delete {
          background: $n-color6;
        }
      }
    }
  }
</style>