// importamos vistas locales
import CursosListaPage from '@/views/cursos/CursosListaPage'
import CursoDetailPage from '@/views/cursos/CursoDetailPage'

const cursosRutes = [
  {
    path: '/cursos/:filtro?',
    name: 'cursos',
    component: CursosListaPage,
  },
  {
    path: 'cursos/detail/:uniqueName',
    name: 'curso-detail',
    component: CursoDetailPage,
  },
]

export default cursosRutes