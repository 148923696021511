import Vue from 'vue'
import App from './App.vue'
//
import './registerServiceWorker'
import router from './router'
import store from './store'

// plugins
import './plugins/material-icons'
import momento from './plugins/moment'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import 'highlight.js/styles/darcula.css'

//
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)
//
Vue.config.productionTip = false

new Vue({
  router,
  store,
  momento,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
