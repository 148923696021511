<template>
  <div class="grid-x grid-margin-x">
    <answer-users />
  </div>
</template>

<script>

  import AnswerUsers from '@/components/users/AnswerUsers'

  export default {
    name: 'AnswerUserPage',
    components: {
      AnswerUsers
    }
  }
</script>

<style lang="scss" scoped>

</style>