<template>
  <div class="grid-x grid-margin-x align-center">
    <div class="cell small-12 medium-8 large-7">
      <profile-user />
    </div>
    <div class="cell small-12 medium-8 large-7">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

  import ProfileUser from '@/components/users/ProfileUser'

  export default {
    name: 'ProfileUserPage',
    components: {
      ProfileUser
    }
  }
</script>

<style lang="scss" scoped>

</style>