<template>
  <div class="cell lego-filters">
    <router-link
      class="lego-filters__link"
      :to="{ name: 'lego', params: {'categoria': 'populares' }}">
        <v-icon-text-inline 
          texto="Todo"
          iconComponent="database-search"
    />
    </router-link>
    <router-link
      class="lego-filters__link"
      v-for="filter in listaFilters"
      :key="filter.id"
      :to="{ name: 'lego', params: {'categoria': filter.unique_name }}">
        <v-icon-text-inline 
        :texto="filter.name"
        :iconComponent="filter.icono"
    />
    </router-link>
  </div>
</template>

<script>

  import apiLego from '@/api/lego'
  import VIconTextInline from '@/components/base/VIconTextInline'

  export default {
    name: 'TheLegoFilters',
    data() {
      return {
        listaFilters: [
        ]
      }
    },
    components: {
      VIconTextInline
    },
    methods: {
      cargarCategorias: function () {
        apiLego.listarCategorias().then(
          (response) => {
            this.listaFilters = response.data
          }
        )
      }
    },
    beforeMount() {
      this.cargarCategorias()
    },
  }
</script>

<style lang="scss" scoped>
  .lego-filters {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    &__link {
      display: block;
      text-align: left;
      color: $n-color2;
      padding: 10px;
    }
  }
</style>