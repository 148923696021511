<template>
  <div v-if="venta" class="pagar">
    <p class="pagar__title">Resumen Pago</p>
    <img 
      :src="venta.lego.image"
      class="pagar__img">
    <p class="pagar__lego">{{ venta.lego.name }}</p>
    <p class="pagar__text">usuario: {{ userData.email }}</p>
    <p class="pagar__text">fecha: {{ $moment().format("DD-MM-YYYY") }}</p>
    <p class="pagar__price">Total: $ {{ venta.amount }}</p>
    <button class="pagar__btn">Completar</button>
  </div>
</template>

<script>
// api
  import apiLego from '@/api/lego.js'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'PaymentProcess',
    computed: {
      ...mapGetters({
        venta: 'venta',
        userData: 'userData'
      })
    },
    data() {
      return {
        loading: false
      }
    },
    methods: {
      ...mapActions({
        setVenta: 'setVenta'
      }),
      generarVenta: function () {
        this.loading = true
        console.log('generando venta...')
        apiLego.retriveLego(this.$route.params.slugLego).then(
          (response) => {
            let venta = {
              'amount': response.data.price,
              'lego': response.data
            }
            this.setVenta(venta)
          }
        ).catch (
          (error) => {
            console.log(error)
          }
        )
      }
    },
    beforeMount() {
      if (!this.venta) {
        this.generarVenta()
      }
    },
  }
</script>

<style lang="scss" scoped>
.pagar {
  width: 100%;
  padding: 10px;
  background: $n-color-white;
  border-radius: 10px;
  margin: 1em;
  text-align: center;
  color: $n-color2;
  @include desde($large) {
    padding: 1em;
  }
  &__title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-family: $n-ff-4;
  }
  &__img {
    width: 100%;
    height: 200px;
    margin-bottom: 10px;
    @include desde($large) {
      width: 100%;
      height: 250px;
    }
  }
  &__lego {
    font-weight: bold;
    font-family: $n-ff-4;
    font-size: 18px;
    text-transform: capitalize;
  }
  &__text {
    font-family: $n-ff-2;
    text-transform: capitalize;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    color: $n-color2;
    padding-left: 10px;
  }
  &__price {
    font-family: $n-ff-4;
    font-size: 24px;
    font-weight: bold;
    color: $n-color3;
  }
  &__btn {
    background: $n-color5;
    color: $n-color-white;
    font-family: $n-ff-2;
    padding: 10px 30px;
    &:hover {
      opacity: 0.6;
      cursor: pointer;
    }
  }
}
</style>