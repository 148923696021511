<template>
  <div v-if="userData" class="perfil-user">
    <div class="perfil-user__body">
      <img
        class="perfil-user__body__img"
        :src="userData.avatar"
      >
      <a 
        class="perfil-user__body__title"
        href="#">
        {{ userData.full_name }}
      </a>
      <p class="perfil-user__body__text">{{ userData.email }}</p>
    </div>
    <div class="perfil-user__footer">
      <router-link 
        class="perfil-user__footer__item"
        :to="{name: 'user-profile'}">
          <comment-question 
            class="perfil-user__footer__item__icon"
            :size="20" 
            fillColor="#212121"
          />
          <span class="perfil-user__footer__item__text">Preguntas</span>
      </router-link >
      <router-link 
        class="perfil-user__footer__item"
        :to="{ name: 'user-answers' }">
          <book-open
            class="perfil-user__footer__item__icon"
            :size="20" 
            fillColor="#212121"
          />
          <span class="perfil-user__footer__item__text">Respuestas</span>
      </router-link>
      <router-link 
        class="perfil-user__footer__item"
        :to="{ name: 'user-lego' }">
          <storefront 
            class="perfil-user__footer__item__icon"
            :size="20" 
            fillColor="#212121"
          />
          <span class="perfil-user__footer__item__text">Lego</span>
      </router-link>
      <a 
        class="perfil-user__footer__item"
        @click="logout()">
          <logout 
            class="perfil-user__footer__item__icon"
            :size="20" 
            fillColor="#212121"
          />
          <span class="perfil-user__footer__item__text">Salir</span>
      </a>
    </div>
  </div>
  <div v-else>
    cargado...
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  //
  export default {
    name: 'ProfileUser',
    computed: {
      ...mapGetters({
        userData: 'userData',
        pending: 'pending',
      })
    },
    methods: {
      ...mapActions({
        recuperarUsuario: 'recuperarUsuario',
        logout: 'logout'
      }),
    },
    beforeMount() {
      if (this.userData === null) {
        this.recuperarUsuario()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .perfil-user {
    width: 100%;
    &__body {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1em;
      &__img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
      }
      &__title {
        text-align: center;
        font-size: 14px;
        font-family: $n-ff-1;
        margin-bottom: 5px;
        width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__text {
        font-size: 12px;
        font-family: $n-ff-2;
      }
    }
    &__footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-bottom: 1em;
      &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 5px;
        &:hover {
          border: 1px solid $n-color2;
          border-radius: 5px;
        }
        &__text {
          font-size: 14px;
          font-family: $n-ff-2;
          color: $n-color2;
        }
      }
    }
  }
</style>