<template>
  <div class="cell grid-x grid-margin-x">
    <div
      :class="nameClass"
      v-for="curso in cursos"
      :key="curso.id">
        <v-card-small  
          :image="curso.image"
          :titulo="curso.title"
          :link="{ name: 'curso-detail', params: {uniqueName: curso.unique_name}}"
          :text="curso.num_stundents + ' estudiantes'"
          :color="curso.color"
        />
    </div>
    <div v-if="loading" class="cell">
      <app-loading-image />
    </div>
  </div>
</template>

<script>
  // api
  import apiCursos from '@/api/cursos'
  import VCardSmall from '@/components/base/VCardSmall'
  //
  import AppLoadingImage from '@/components/apps/AppLoadingImage'


  export default {
    name: 'MainCursos',
    data() {
      return {
        cursos: [],
        loading: false
      }
    },
    props: {
      nameClass: String
    },
    components: {
      VCardSmall,
      AppLoadingImage
    },
    methods: {
      listaCursos: function () {
        this.loading = true
        apiCursos.listMainCursos().then(
          (response) => {
            this.cursos = response.data
            this.loading = false
          }
        ).catch(() => {
          this.loading = false
        })
      }
    },
    beforeMount() {
      this.listaCursos()
    },
  }
</script>

<style lang="scss" scoped>
</style>