<template>
  <div class="grid-x grid-margin-x">
    <the-lego-galery />
  </div>
</template>

<script>
  import TheLegoGalery from '@/components/lego/TheLegoGalery.vue'

  export default {
    name: 'LegoGalery',
    components: {
      TheLegoGalery
    }
  }
</script>

<style lang="scss" scoped>

</style>