var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.banner)?_c('div',{staticClass:"grid-x grid-margin-x"},[_c('div',{staticClass:"cell small-12 large-8"},[_c('div',{staticClass:"banner-portada"},[_c('img',{staticClass:"banner-portada__img",attrs:{"src":_vm.banner.entry_outstanding.image,"alt":_vm.banner.entry_outstanding.title}}),_c('div',{staticClass:"banner-portada__copy"},[_c('p',{staticClass:"banner-portada__copy__text"},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.banner.entry_outstanding.created))+" ")]),_c('h1',{staticClass:"banner-portada__copy__title"},[_vm._v(" "+_vm._s(_vm.banner.entry_outstanding.title)+" ")]),_c('router-link',{staticClass:"banner-portada__copy__btn",attrs:{"to":{ name: 'blog-detail', params: {slug: _vm.banner.entry_outstanding.slug}}}},[_c('span',{staticClass:"banner-portada__copy__btn__text"},[_vm._v(" Ver mas ")]),_c('send',{staticClass:"banner-portada__copy__btn__icon",attrs:{"size":18}})],1)],1)])]),_c('div',{staticClass:"cell small-12 large-4 grid-x grid-margin-x"},[_c('div',{staticClass:"cell small-6 medium-3 large-6"},[_c('v-card-small',{attrs:{"image":_vm.banner.curso_one.image,"titulo":_vm.banner.curso_one.title,"link":{ 
            name: 'curso-detail', 
            params: {uniqueName: _vm.banner.curso_one.unique_name}},"text":_vm.banner.curso_one.num_stundents + ' estudiantes',"color":_vm.banner.curso_one.color}})],1),_c('div',{staticClass:"cell small-6 medium-3 large-6"},[_c('v-card-small',{attrs:{"image":_vm.banner.curso_two.image,"titulo":_vm.banner.curso_two.title,"link":{ 
            name: 'curso-detail', 
            params: {uniqueName: _vm.banner.curso_two.unique_name}},"text":_vm.banner.curso_two.num_stundents + ' estudiantes',"color":_vm.banner.curso_two.color}})],1),_c('div',{staticClass:"cell small-6 medium-3 large-6"},[_c('v-card-small',{attrs:{"image":_vm.banner.curso_three.image,"titulo":_vm.banner.curso_three.title,"link":{ 
            name: 'curso-detail', 
            params: {uniqueName: _vm.banner.curso_three.unique_name}},"text":_vm.banner.curso_three.num_stundents + ' estudiantes',"color":_vm.banner.curso_three.color}})],1),_c('div',{staticClass:"cell small-6 medium-3 large-6"},[_c('v-card-small',{attrs:{"image":_vm.banner.curso_four.image,"titulo":_vm.banner.curso_four.title,"link":{ 
            name: 'curso-detail', 
            params: {uniqueName: _vm.banner.curso_four.unique_name}},"text":_vm.banner.curso_four.num_stundents + ' estudiantes',"color":_vm.banner.curso_four.color}})],1)])]):_c('div',[(_vm.loading)?_c('app-loading-image'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }