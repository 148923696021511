<template>
  <div>
   <the-filter-blogs />
  </div>
</template>

<script>

  import TheFilterBlogs from '@/components/blogs/TheFilterBlogs.vue'

  export default {
    name: 'BlogPage',
    metaInfo: {
      title: 'tutoriales django rest framewrok y vue',
      titleTemplate: null,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Articulos sobre Django, vue y desarrollo web',
      }]
    },
    components: {
      TheFilterBlogs
    }
  }
</script>

<style lang="scss" scoped>

</style>