<template>
  <div class="card-text" :style="{backgroundImage: 'url(' + imagen + ')'}">
    <span
      class="card-text__category"
    >
      {{ categoria }}
    </span>
    <h3 class="card-text__title">
      {{ titulo }}
    </h3>
    <router-link 
      class="card-text__link"
      :to="link">
      Leer el Articulo
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'VCardText',
  props: {
    titulo: String,
    imagen: String,
    categoria: String,
    link: Object,
  }
}
</script>

<style lang="scss" scoped>
  .card-text {
    padding: 15px;
    border-radius: 10px;
    color: $n-color-white;
    margin-bottom: 1em;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    background-size:100%;
    @include desde(1904px) {
      padding: 2em;
    }
    &__category {
      display: block;
      font-family: $n-ff-3;
      font-weight: lighter;
      font-size: 12px;
      margin-bottom: 1em;
    }
    &__title {
      font-family: $n-ff-2;
      font-weight: bold;
      font-size: 26px;
      height: 80px;
      overflow: hidden;
      margin-bottom: 1.5em;
      background-color: rgba(27, 26, 26, 0.4);
      border-radius: 10px;
      padding: 10px;
    }
    &__link {
      display: block;
      background: $n-color-white;
      width: 180px;
      text-align: center;
      font-family: $n-ff-5;
      text-transform: capitalize;
      font-weight: bold;
      padding: 10px 20px 10px 20px;
      border-radius: 10px;
      color: $n-color2;
    }
  }
</style>