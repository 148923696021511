<template>
  <the-lego-detail />
</template>

<script>

  import TheLegoDetail from '@/components/lego/TheLegoDetail.vue'

  export default {
    name: 'LegoDetail',
    components: {
      TheLegoDetail
    }
  }
</script>

<style lang="scss" scoped>

</style>