<template>
  <div class="grid-x grid-margin-x">
    <lista-cursos-destacados 
      titulo="Cursos Destacados"
      :size="4"
    />
    <lista-cursos 
      titulo="Cursos Publicados"
      :size="12"
      :show-ctrls="false"
    />
  </div>
</template>

<script>

  import ListaCursosDestacados from '@/components/cursos/ListaCursosDestacados.vue'
  import ListaCursos from '@/components/cursos/ListaCursos.vue'

  export default {
    name: 'CursosListaPage',
    metaInfo: {
      title: 'Curso de Django, Django rest framework y Vue',
      titleTemplate: null,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'Cursos de programacion web de basico a profesional',
      }]
    },
    components: {
      ListaCursos,
      ListaCursosDestacados
    }
  }
</script>

<style lang="scss" scoped>

</style>