<template>
  <a 
    class="primary-button"
    @click="toUrl()"
    >
    {{ text }}
  </a>
</template>

<script>
export default {
  name: 'VButtonPrimary',
  props: {
    link: {
      type: Object,
      default: function () {
        return {name: 'home', params: {}}
      }
    },
    text: String,
  },
  methods: {
    toUrl: function () {
      this.$router.push(
        {
          name: this.link.name,
          params: this.link.params
        }
      )
    }
  },
}
</script>

<style lang="scss" scoped>
  .primary-button {
    display: block;
    border: 2px solid $n-color3;
    padding: 7px 2em 7px 2em;
    border-radius: 15px;
    min-width: 120px;
    font-size: 14px;
    font-family: $n-ff-1;
    text-align: center;
    color: $n-color2;
    font-weight: bold;
  }
</style>