<template>
  <div class="success">
    <p 
      class="success__text"
      :style="{ color: color, fontFamily: fontFamily  }">
        {{ texto }}
    </p>
  </div>
</template>
<script>
export default {
  name: 'AppSuccess',
  props: {
    texto: String,
    color: String,
    fontFamily: String
  }
}
</script>
<style lang="scss">
  .success {
    background: rgba(90, 90, 90, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 300;
    &__text {
      font-size: 16px;
      width: 300px;
      font-weight: bold;
    }
  }
</style>
