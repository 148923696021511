<template>
  <div>
    <the-detail-blog />
  </div>
</template>

<script>

  import TheDetailBlog from '@/components/blogs/TheDetailBlog'

  export default {
    name: 'BlogDetailPage',
    components: {
      TheDetailBlog
    }
  }
</script>

<style lang="scss" scoped>

</style>