<template>
  <div class="pregunta">
    <h3 class="pregunta__title">Formula tu Pregunta</h3>
    <form 
      class="add-pregunta"
      method="POST"
      @submit.prevent="revisarCapcha()"
      >
      <div class="">
        <input 
          type="text" 
          placeholder="Titulo, tema o curso"
          v-model="titulo"
          required>
      </div>
      <div style="height: 250px;">
        <quill-editor
          :content="content"
          :options="editorOption"
          @change="onEditorChange($event)"
          style="position: relative; height: 150px"
        />
      </div>
      <div class="add-pregunta__actions">
        <a
          class="add-pregunta__actions__btn"
          @click="method()">
          Salir
        </a>
        <button 
          class="add-pregunta__actions__btn delete"
          type="submit">
          Publicar
        </button>
      </div>

      <app-loading v-if="loading" />
      <app-msj-alert
        v-if="showAlter"
        mensaje="Excelente!!! puedes ver tus preguntas en tu perfil"
        actionMain="Ir al perfil"
        actionClose="De Acuerdo"
        :link="{name: 'user-profile'}"
        :method="closeAlert"
      />
    </form>
  </div>
</template>

<script>

  import Vue from 'vue'
  import { VueReCaptcha } from 'vue-recaptcha-v3'

  Vue.use(VueReCaptcha, { siteKey: '6LdpqtIZAAAAAGn3FlR8hal0_mr4HKpvG7xAc7Kw' })

  // quill editor
  import { quillEditor } from 'vue-quill-editor'
  import hljs from 'highlight.js'
  import 'highlight.js/styles/darcula.css'

  import apiPregunta from '@/api/pregunta'
  // 
  import AppLoading from '@/components/apps/AppLoading'
  //
  import AppMsjAlert from '@/components/apps/AppMsjAlert'

  export default {
    name: 'ForoAddPregunta',
    components: {
      quillEditor,
      AppLoading,
      AppMsjAlert
    },
    data() {
      return {
        loading: false,
        showAlter: false,
        content: '',
        objetoEditor: null,
        titulo: '',
        editorOption: {
          placeholder: 'Escribe tu respuesta...',
          theme: 'snow',
          modules: {
            syntax: {
              highlight: text => hljs.highlightAuto(text).value,
            },
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
              ['blockquote', 'code-block'],

              [{ 'header': 1 }, { 'header': 2 }],               // custom button values
              [{ 'list': 'ordered'}, { 'list': 'bullet' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
              [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
              [{ 'direction': 'rtl' }],                         // text direction

              [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
              [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

              [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
              [{ 'font': [] }],
              [{ 'align': [] }],

              ['clean'] 
            ]
          }
        }
      }
    },
    props: {
      method: {type: Function} 
    },
    methods: {
      onEditorChange({ quill }) {
        this.objetoEditor = quill.editor.delta.ops
      },
      limpiarEditor: function () {
        this.content = null
      },
      revisarCapcha: async function() {
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('login')
        if (token) {
          this.saveQuestion()
        } 
      },
      saveQuestion: function () {
        this.loading = true
        let data = {
          titulo: this.titulo,
          content: JSON.stringify(this.objetoEditor),
        }
        apiPregunta.enviarPregunta(data).then(
          (response) => {
            this.loading = false
            this.content = null
            if (response.data.respuesta === 'ok') {
              this.showAlter = true
            }
          }
        ).catch(
          (error) => {
            console.log(error)
            this.loading = false
          }
        )
      },
      closeAlert: function () {
        this.showAlter = false
        this.method()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .pregunta {
    position: fixed;
    top: 0px;
    left: 0px;
    border: 1px solid;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(53, 52, 52, 0.5);
    flex-direction: column;
    &__title {
      font-size: 12px;
      font-family: $n-ff-1;
      font-weight: bold;
      color: $n-color6;
      background: $n-color-white;
      padding: 3px 10px;
      text-transform: capitalize;
    }
  }
  .add-pregunta {
    background: $n-color-white;
    border-radius: 10px;
    padding: 1em;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    margin-bottom: 1em;
    @include hasta(720px) {
      width: 100%;
    }
    @include desde(720px) {
      width: 50%;
    }
    @include desde(1904px) {
      width: 40%;
    }
    &__actions {
      margin-top: 1em;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 12px;
      font-family: $n-ff-2;
      &__btn {
        background: $n-color4;
        padding: 5px 15px 5px 15px;
        margin-right: 10px;
        color: $n-color-white;
        &.delete {
          background: $n-color6;
        }
      }
    }
  }
</style>