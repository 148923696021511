<template>
  <div v-if="respuesta" style="margin-bottom: 2em;">
    <v-blog-content
      :image="getPhoto"
      :autor="respuesta.author.full_name"
      :fecha="formatDate(respuesta.date)"
      :content="respuesta.content"
    />
    <div class="more-answers">
      <div 
        class="more-answers__item"
        v-for="answer in listaRespuestas"
        :key="answer.id">
          <v-blog-content
            :image="answer.author.avatar"
            :autor="answer.author.full_name"
            :fecha="formatDate(answer.date)"
            :content="answer.content"
          />
      </div>
      <a 
        @click="masRespuestas()"
        class="more-answers__action">
        Cargar mas respuestas
      </a>
    </div>


    <app-loading v-if="loading"/>
  </div>
  <div v-else>
    <p>cargando respuestas ...</p>
  </div>
</template>

<script>
  //
  import apiPregunta from '@/api/pregunta'
  //
  // 
  import AppLoading from '@/components/apps/AppLoading'
  import logo_neunapp from '@/assets/logo_neunapp.png'
  //
  import VBlogContent from '@/components/base/VBlogContent'

  export default {
    name: 'ForoRespuestasPregunta',
    data() {
      return {
        respuesta: null,
        loading: false,
        moreResults: true,
        page: 0,
        listaRespuestas: []
      }
    },
    components: {
      VBlogContent,
      AppLoading
    },
    computed: {
      getPhoto: function () {
        if (this.respuesta.author.avatar) {
          return this.respuesta.author.avatar
        } else {
          return logo_neunapp
        }
      }
    },
    methods: {
      formatDate: function (value) {
        return this.$moment(value).format('DD MMMM YYYY')
      },
      cargarRespuestas: function () {
        apiPregunta.recuperaRespuesta(this.$route.params.slug).then(
          (response) => {
            this.respuesta = response.data
          }
        )
      },
      masRespuestas: function () {
        if (this.moreResults) {
          this.loading = true
          this.page = this.page + 1
          apiPregunta.respuestasPregunta(this.$route.params.slug, this.page).then(
            (response) => {
              this.listaRespuestas = this.listaRespuestas.concat(response.data.results)
              this.loading = false
              if (response.data.next) {
                this.moreResults = true
              } else {
                this.moreResults = false
              }
            }
          ).catch(() => {
            this.loading = false
          })
        }
      }
    },
    beforeMount() {
      this.cargarRespuestas()
    },
  }
</script>

<style lang="scss" scoped>
  .more-answers {
    padding: 15px;
    border-radius: 20px;
    background: $n-color-white;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    &__action {
      font-size: 14px;
      font-family: $n-ff-2;
      color: $n-color2;
      display: block;
      width: 100%;
      text-align: center;
      text-decoration: underline;
    }
  }
</style>