// services for user api
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import global settings
import baseConf from '@/Base'

Vue.use(VueAxios, axios)

// instance for atentication
var instance = axios.create({
  baseURL: baseConf.urlApi,
  timeout: 12000
})

const apiPregunta = {
  getPregunta: function (slug) {
    return instance.get('/api/pregunta/' + slug + '/')
  },
  listaPreguntasPopulares: function(kword, curso) {
    return instance.get('/api/preguntas/lista/?' + 'kword=' + kword + '&curso=' + curso)
  },
  recuperaRespuesta: function (slug) {
    return instance.get('/api/respuesta/principal/' + slug + '/')
  },
  enviarRespuesta: function (data) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.post('/api/respuesta/add/', data, header)
  },
  enviarPregunta: function (data) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.post('/api/nueva-pregunta/add/', data, header)
  },
  respuestasPregunta: function (slug, page) {
    return instance.get('/api/respuesta/por-pregunta/' + slug + '/?page=' + page)
  },
  preguntasPorUsuario: function (page) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.get('/api/preguntas/por-usuario/?page=' + page, header)
  },
  respuestasPorUsuario: function (page) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.get('/api/respuestas/por-usuario/?page=' + page, header)
  },
  eliminarPreguntaId: function (id) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.delete('/api/pregunta/delete/id/' + id + '/', header)
  },
  eliminarRespuestaId: function (id) {
    var header = {
      headers: {
        'Authorization': 'Token ' + localStorage.getItem('token')
      }
    }
    return instance.delete('/api/respuesta/delete/id/' + id + '/', header)
  },
  recuperarRespuestaID: function (id) {
    return instance.get('/api/respuestas/review/id/' + id + '/')
  },
  recuperarPreguntaID: function (id) {
    return instance.get('/api/pregunta-get/review/id/' + id + '/')
  }
}

export default apiPregunta