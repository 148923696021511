import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
//
import homeRutes from './home'

Vue.use(VueRouter)
//
Vue.use(Meta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})

const routes = homeRutes

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
