<template>
  <div>
    <div v-if="content" class="blog-content">
      <div class="blog-content__head">
        <img
          class="blog-content__head__img"
          :src="image" 
          :alt="autor"
          @error="setAltImg">
        <div class="blog-content__head__credits">
          <p class="blog-content__head__credits__name">
            {{autor}}
          </p>
          <p class="blog-content__head__credits__date">
            {{fecha}}
          </p>
        </div>
      </div>
      <div class="blog-content__body" v-html="content">
      </div>
      <div class="blog-content__footer">
        <span class="blog-content__footer__text">¿La respuesta fue util?</span>
        <button class="blog-content__footer__btn">Si</button>
        <button class="blog-content__footer__btn">No</button>
      </div>
    </div>
  </div>
</template>

<script>

  import notImg from '@/assets/001.png'

  export default {
    name: 'VBlogContent',
    props: {
      image: String,
      autor: String,
      fecha: String,
      content: String
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    },
  }
</script>

<style lang="scss" scoped>
  .blog-content{
    padding: 15px;
    border-radius: 20px;
    background: $n-color-white;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    margin-bottom: 1em;
    &__head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 10px;
      &__img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }
      &__credits {
        padding: 5px;
        &__name {
          margin-bottom: 3px;
          font-size: 12px;
          font-family: $n-ff-1;
          text-decoration: underline;
          color: $n-color2;
        }
        &__date {
          margin-bottom: 3px;
          font-size: 11px;
          font-family: $n-ff-4;
          color: $n-color3;
        }
      }
    }
    &__body {
      font-family: $n-ff-4;
      font-size: 14px;
      margin-bottom: 10px;
      color: $n-color2;
      @include desde(1000px) {
        font-size: 16px;
        margin-bottom: 1em;
      }
    }
    &__footer {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1em;
      padding: 5px;
      &__text {
        font-family: $n-ff-1;
        color: $n-color5;
        margin-right: 10px;
      }
      &__btn {
        margin-right: 10px;
        background: $n-color-white;
        color: $n-color1;
        border: 1px solid;
        padding: 5px 15px 5px 15px;
        font-size: 12px;
        &:hover {
          background: $n-color6;
          color: $n-color-white;
        }
      }
    }
  }
</style>