<template>
  <div class="grid-x grid-margin-x">

    <div class="cell filter-blog">
      <div class="filter-blog__title">
        <animation-play-outline 
          class="filter-blog__title__icon"
          :size="20"
        />
        <span class="filter-blog__title__text">Nuestro contenido, videos y blogs:</span>
      </div>
      <div class="filter-blog__ctrls">
        <a 
          @click="listarBlogs('blogs')"
          class="filter-blog__ctrls__btn blog">Blogs</a>
        <a
          @click="listarBlogs('videos')"
          class="filter-blog__ctrls__btn video">Videos</a>
      </div>
      <div class="filter-blog__actions">
        <button 
          class="filter-blog__actions__btn"
          @click="showRecomendacion=true">
            Recomendar Tema
        </button>
      </div>
    </div>

    <div class="cell grid-x grid-margin-x">
      <div 
        class="cell small-12 medium-6 large-4"
        v-for="blog in listaBlogs" :key="blog.id">
          <v-card-contenido 
            :image="blog.image"
            :image-secondary="blog.category.image"
            :titulo="blog.title"
            :tag="blog.category.name"
            :fecha="formatDate(blog.created)"
            :color="blog.color"
            :link="{name: 'blog-detail', params: {slug: blog.slug}}"
          />
      </div>
    </div>
    <a
      v-if="moreResults"
      class="load-more" 
      @click="cargarMasResultados()">Cargar más resultados</a>


      <recomendar-tema 
        v-if="showRecomendacion"
        :method="cerrarRecomendacion" 
      />


    <app-loading v-if="loading"/>
  </div>
</template>

<script>
  // api
  import apiBlog from '@/api/blog'
  import AppLoading from '@/components/apps/AppLoading'
  //
  import VCardContenido from '@/components/base/VCardContenido.vue'
  import RecomendarTema from '@/components/blogs/RecomendarTema'

  export default {
    name: 'TheFilterBlogs',
    components: {
      VCardContenido,
      RecomendarTema,
      AppLoading
    },
    data() {
      return {
        listaBlogs: [],
        loading: false,
        moreResults: true,
        page: 1,
        filtro: null,
        showRecomendacion: false,
      }
    },
    methods: {
      formatDate: function (value) {
        return this.$moment(value).format('MMMM DD YYYY');
      },
      listarBlogs: function (filtro) {
        this.loading = true
        this.filtro = filtro
        apiBlog.listaBlogs(filtro, this.page).then(
          (response) => {
            this.listaBlogs = response.data.results
            this.loading = false
            if (response.data.next) {
              this.moreResults = true
            } else {
              this.moreResults = false
            }
          }
        ).catch(() => {
          this.loading = false
        })
      },
      cargarMasResultados: function () {
        if (this.moreResults) {
          this.page = this.page + 1
          this.loading = true
          apiBlog.listaBlogs(this.filtro, this.page).then(
            (response) => {
              this.listaBlogs = this.listaBlogs.concat(response.data.results)
              this.loading = false
              if (response.data.next) {
                this.moreResults = true
              } else {
                this.moreResults = false
              }
            }
          ).catch(() => {
            this.loading = false
          })
        }
      },
      cerrarRecomendacion: function () {
        this.showRecomendacion = false
      }
    },
    beforeMount() {
      this.listarBlogs()
    },
  }
</script>

<style lang="scss" scoped>
  .filter-blog {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
    &__title {
      display: flex;
      justify-content: center;
      align-items: center;
      &__text {
        font-family: $n-ff-1;
        font-size: 11px;
        margin-left: 5px;
        margin-bottom: 3px;
        @include desde (1000px) {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
    &__ctrls {
      display: flex;
      justify-content: center;
      align-items: center;
      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #bdc3c7;
        height: 30px;
        font-size: 12px;
        width: 60px;
        color: $n-color2;
        @include desde (1000px) {
          font-size: 14px;
          width: 100px;
        }
        &:hover {
          cursor: pointer;
        }
        &:active {
          opacity: 0.5;
          border: none;
        }
        &.video {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
        &.blog {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
      }
    }
    &__actions {
      @include hasta (1000px) {
        display: none;
      }
      &__btn {
        border: 1px solid;
        font-size: 14px;
        font-family: $n-ff-1;
        padding: 7px 15px 7px 15px;
        border-radius: 15px;
        background: $n-color5;
        color: $n-color-white;
      }
    }
  }

  .load-more {
    display: block;
    width: 100%;
    text-align: center;
    font-family: $n-ff-1;
    color: $n-color2;
    font-weight: bold;
  }
</style>