import { render, staticRenderFns } from "./TheDetailBlog.vue?vue&type=template&id=b3af98ba&scoped=true&"
import script from "./TheDetailBlog.vue?vue&type=script&lang=js&"
export * from "./TheDetailBlog.vue?vue&type=script&lang=js&"
import style0 from "./TheDetailBlog.vue?vue&type=style&index=0&id=b3af98ba&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3af98ba",
  null
  
)

export default component.exports