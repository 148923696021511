<template>
  <div class="cell grid-x grid-margin-x destacados">
    <h3 class="cell destacados-title">{{ titulo }}</h3>
    <main-cursos
      name-class="cell small-6 medium-4 large-2"
    />
  </div>
</template>

<script>

  import MainCursos from '@/components/cursos/MainCursos'

  export default {
    name: 'ListaCursosDestacados',
    props: {
      titulo: String,
    },
    components: {
      MainCursos
    }
  }
</script>

<style lang="scss" scoped>
.destacados {
  background: $n-color-white;
  padding-left: 1em;
  padding-right: 1em;
  margin-bottom: 1em;
}
.destacados-title {
  font-family: $n-ff-1;
  margin-bottom: 1em;
  margin-top: 1em;
  font-size: 14px;
  font-weight: bold;
}
</style>