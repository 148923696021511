// configuracion de claves de acceso firebase
export const config = {
  apiKey: "AIzaSyClQnq2oI0TN2L1xDdp73A2YfjuHGwIi6M",
  authDomain: "neunapp-2c774.firebaseapp.com",
  databaseURL: "https://neunapp-2c774.firebaseio.com",
  projectId: "neunapp-2c774",
  storageBucket: "neunapp-2c774.appspot.com",
  messagingSenderId: "563619235330",
  appId: "1:563619235330:web:a524075e3b9d50f708491b",
  measurementId: "G-VMS7G94T0J"
}