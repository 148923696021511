<template>
  <div v-if="lego" class="grid-x grid-margin-x">
    <div class="cell small-12 large-9">
      <v-card-blog 
        :titulo="lego.name"
        :resumen="lego.short_description"
        :iconsText="listaIconosText"
        :video="lego.trailer"
        :image="lego.image"
        :texto="lego.resume"
        :fecha=" 'Autor: ' + lego.author"
      />
    </div>
    <div class="cell small-12 large-3">
      <v-card-info
        color="#e67e22"
        textAction="Obtener"
        :method="verificarUsuario"
        :title="lego.name"
        :details="detallesLego"
      />
    </div>
    <app-msj-alert 
      v-if="showAlert"
      mensaje="Primero Inicia Sesion para realziar esta operación"
      actionMain="De Acuerdo"
      actionClose="Cancelar"
      :link="{name: 'login'}"
      :method="closeAlert"
    />
  </div>
  <div v-else>
    <p>cargando....</p>
  </div>
</template>

<script>
  // api
  import apiLego from '@/api/lego.js'
  //vuex
  import {mapActions, mapGetters} from 'vuex'
  // components
  import VCardBlog from '@/components/base/VCardBlog'
  import VCardInfo from '@/components/base/VCardInfo'
  import AppMsjAlert from '@/components/apps/AppMsjAlert'

  export default {
    name: 'TheLegoDetail',
    data() {
      return {
        lego: null,
        showAlert: false
      }
    },
    components: {
      VCardBlog,
      VCardInfo,
      AppMsjAlert
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
        userData: 'userData'
      }),
      listaIconosText: function () {
        return [
          {'id': 0, 'name': this.lego.lenguaje.name, 'icono': 'code-braces-box'},
          {'id': 1, 'name': this.lego.tag, 'icono': 'iframe-braces'},
        ]
      },
      detallesLego: function () {
        return [
          {'id': 0, 'name': 'Precio: $ ' + this.lego.price },
          {'id': 1, 'name': 'Lenguaje: ' + this.lego.lenguaje.name },
          {'id': 2, 'name': 'Tag: ' + this.lego.tag },
          {'id': 3, 'name': 'Publicado: ' +  this.$moment(this.lego.streno).format('MM-YYYY') },
        ]
      }
    },
    methods: {
      ...mapActions({
        setVenta: 'setVenta'
      }),
      recuperarLego: function () {
        apiLego.retriveLego(this.$route.params.slugLego).then(
          (response) => {
            this.lego = response.data
          }
        ).catch (
          (error) => {
            console.log(error)
          }
        )
      },
      closeAlert: function () {
        this.showAlert = false
      },
      verificarUsuario: function () {
        if (!this.isLoggedIn) {
          this.showAlert = true
        } else {
          this.generarVenta()
        }
      },
      generarVenta: function () {
        console.log('====== generando venta =======')
        let venta = {
          'amount': this.lego.price,
          'lego': [
            {
              'lego': this.lego
            }
          ]
        }
        this.setVenta(venta)
        this.$router.push({name: 'lego-venta', params: {slugLego: this.lego.slug}})
      }
    },
    created () {
      this.recuperarLego()
    }
  }
</script>

<style lang="scss" scoped>

</style>