<template>
  <div class="cell metodo-pago">
    <h3 class="metodo-pago__title">Seleccione un método de pago:</h3>
    <div class="metodo-pago__metodos">
      <div class="metodo-pago__metodos__item">
        <a href="#" class="metodo-pago__metodos__item__name">Paypal</a>
        <a href="#" class="metodo-pago__metodos__item__btn">Pagar</a>
      </div>
      <div class="metodo-pago__metodos__item">
        <a href="#" class="metodo-pago__metodos__item__name">Payoner</a>
        <a href="#" class="metodo-pago__metodos__item__btn">Pagar</a>
      </div>
      <div class="metodo-pago__metodos__item">
        <a href="#" class="metodo-pago__metodos__item__name">PLIN</a>
        <a href="#" class="metodo-pago__metodos__item__btn">Pagar</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PaymentMethod'
  }
</script>

<style lang="scss" scoped>
  .metodo-pago {
    padding: 10px;
    background: $n-color-white;
    border-radius: 10px;
    margin: 1em;
    &__title {
      font-family: $n-ff-2;
      font-size: 14px;
      font-weight: bold;
      color: $n-color4;
      text-transform: capitalize;
      margin-bottom: 1em;
    }
    &__metodos {
      padding: 10px;
      &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: $n-ff-2;
        padding: 10px;
        margin-bottom: 10px;
        &:hover {
          border: 1px solid $n-color1;
          border-radius: 10px;
        }
        &__name {
          color: $n-color2;
          text-decoration: none;
          font-weight: bold;
        }
        &__btn {
          padding: 5px 20px;
          border-radius: 10px;
          background: $n-color5;
          color: $n-color2;
          &:hover {
            color: $n-color-white;
          }
        }
      }
    }
  }
</style>