<template>
  <div class="card-contenido">
    <img
      class="card-contenido__img"
      :src="image" 
      :alt="titulo"
      @error="setAltImg">
    <img 
      class="card-contenido__icono"
      :src="imageSecondary"
      @error="setAltImg">
    <div class="card-contenido__body" :style="{ backgroundColor: color }">
      <router-link 
        :to="link"
        class="card-contenido__body__title">
        {{ titulo }}
      </router-link>
      <div class="card-contenido__body__footer">
        <span class="card-contenido__body__footer__l">
          {{ fecha }}
        </span>
        <span class="card-contenido__body__footer__r">
          {{ tag }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import notImg from '@/assets/001.png'
  //
  export default {
    name: 'VCardContenido',
    props: {
      color: {
        type: String,
        default: '#1abc9c'
      },
      titulo:String,
      image: String,
      imageSecondary: String,
      fecha: String,
      tag: String,
      icono: String,
      link: Object
    },
    methods: {
      setAltImg(event) { 
        event.target.src = notImg
      }, 
    },
  }
</script>

<style lang="scss" scoped>
  .card-contenido {
    position: relative;
    height: 300px;
    width: 100%;
    margin-bottom: 1em;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    border-radius: 10px;
    @include desde (1904px) {
      height: 350px;
    }
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
    &__icono {
      display: block;
      position: absolute;
      left: 10px;
      z-index: 300;
      top: 160px;
      width: 50px;
      height: 50px;
      border-radius: 15px;
      padding: 10px;
      margin: 0px;
      background: #2ecc71;
      @include desde (1904px) {
        top: 220px;
      }
    }
    &__body {
      width: 100%;
      position: absolute;
      bottom: 0px;
      height: 250px;
      border-radius: 10px;
      clip-path: polygon(0 57%, 100% 42%, 100% 100%, 0% 100%);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 1em;
      &__title {
        color: $n-color-white;
        font-family: $n-ff-1;
        font-size: 16px;
        font-weight: bold;
        // margin-bottom: 2em;
        // margin-left: 4em;
        position: absolute;
        left: 70px;
        bottom: 60px;
        &:hover {
          color: $n-color-white;
        }
      }
      &__footer {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &__l {
          font-size: 12px;
          color: $n-color-white;
          font-family: $n-ff-4;
        }
        &__r {
          font-size: 12px;
          padding: 3px 10px 3px 10px;
          border-radius: 10px;
          border: 1px solid;
          opacity: 0.7;
          color: $n-color-white;
        }
      }
    }
  }
</style>