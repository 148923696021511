<template>
  <div class="loading">
    <img 
      class="loading__image"
      src="../../assets/loader.gif" 
      alt="cargando"
    >
  </div>
</template>
<script>
export default {
  name: 'AppLoadingImage'
}
</script>
<style lang="scss">
  .loading {
    display: block;
    width: 100%;
    height: 100%;
    &__img {
      width: 100%;
      height: 100%;
    }
  }
</style>
