<template>
  <div class="preview">
    <div v-html="contenido">
    </div>
  </div>
</template>

<script>
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
  import apiPregunta from '@/api/pregunta'

  export default {
    name: 'PreviewQuestionUser',
    data() {
      return {
        contenido: ''
      }
    },
    methods: {
      convertirOpsHtml: function (deltaOps) {
        let delta = JSON.parse(deltaOps)
        let cfg = {}
        let converter = new QuillDeltaToHtmlConverter(delta, cfg)
        return converter.convert()
      },
      recuperarPregunta: function () {
        apiPregunta.recuperarPreguntaID(this.$route.params.id).then(
          (response) => {
            this.contenido = this.convertirOpsHtml(response.data.content_json)
          }
        )
      }
    },
    beforeMount() {
      this.recuperarPregunta()
    },
  }
</script>

<style lang="scss" scoped>

</style>