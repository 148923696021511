<template>
  <div class="donation">
    <a 
      class="donation__social"
      href="https://www.youtube.com/channel/UCcZoaMlaUXf_0KFXrEWt2Ww?view_as=subscriber">
      <v-icon-text-inline 
        texto="Youtube"
        iconComponent="youtube"
      />
    </a>
    <a 
      class="donation__social"
      href="https://twitter.com/cscthian">
      <v-icon-text-inline 
        texto="Twitter"
        iconComponent="twitter"
      />
    </a>
    <a
      class="donation__action" 
      href="https://paypal.me/neunapp?locale.x=es_XC">
      Donar
    </a>
  </div>
</template>

<script>

  import VIconTextInline from '@/components/base/VIconTextInline'

  export default {
    name: 'DonationsBlock',
    components: {
      VIconTextInline
    }
  }
</script>

<style lang="scss" scoped>
  .donation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1em 0px 1em 0px;
    padding: 1em;
    &__social {
      color: $n-color2;
    }
    &__action {
      border: 1px solid;
      padding: 5px 2em 5px 2em;
      border-radius: 10px;
      color: white;
      background: $n-color6;
      &:hover {
        color: white;
      }
    }
  }
</style>