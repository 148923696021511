<template>
  <div>
    <the-header></the-header>
    <!-- Pagina web no encontrada -->
    <div 
      class="grid-container full">
      <div class="error-404">
        <img 
          class="error-404__img"
          src="../assets/404.png" 
        >
        <div class="error-404__body">
          <div class="error-404__body__copy">
            <h1 class="error-404__body__copy__error">404</h1>
            <h3 class="error-404__body__copy__title">
              Hemos actualizado neunapp prueba una de estas opciones
            </h3>
            <div class="error-404__body__copy__ctrls">
              <router-link
                :to="{name: 'foro'}"
                class="error-404__body__copy__ctrls__btn">
                Foro
              </router-link>
              <router-link 
                :to="{name: 'blog'}"
                class="error-404__body__copy__ctrls__btn">
                Articulos
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import TheHeader from '@/components/includes/TheHeader'

export default {
  name: 'PaginaNoEncontrada',
  components: {
    TheHeader
  }
}
</script>

<style lang="scss">
  .error-404 {
    display: block;
    width: 100%;
    height: 90vh;
    position: relative;
    &__img {
      display: block;
      width: 100%;
      height: 100%;
    }
    &__body {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      &__copy {
        text-align: center;
        background: rgba(39, 41, 41, 0.404);
        padding: 2em;
        border-radius: 20px;
        &__error {
          color: $n-color-white;
          font-family: $n-ff-4;
        }
        &__title {
          font-size: 18px;
          font-family: $n-ff-1;
          text-transform: capitalize;
          color: $n-color-white;
          font-weight: bold;
        }
        &__ctrls {
          display: flex;
          justify-content: center;
          align-items: center;
          &__btn {
            padding-top: 5px;
            padding-bottom: 5px;
            display: block;
            width: 120px;
            margin: 10px;
            border-radius: 10px;
            color: $n-color-white;
            background: $n-color2;
            text-align: center;
            font-size: 14px;
            font-family: $n-ff-1;
            &:hover {
              color: $n-color-white;
            }
          }
        }
      }
    }
  }
</style>