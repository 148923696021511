<template>
  <div v-if="pregunta" class="question">
    <div class="question__head">
      <div class="question__head__left">
        <img 
          class="question__head__left__img"
          :src="getPhoto" 
          :alt="pregunta.user.full_name"
        >
        <span class="question__head__left__nike">
          {{ pregunta.user.full_name }}
        </span>
        <span class="question__head__left__date">
          publicado el {{ formatDate(pregunta.created) }}
        </span>
      </div>
      <div class="question__head__right">
        <span 
          class="question__head__right__tag"
          :style="{ background: pregunta.curso.color }">
          {{ pregunta.curso.lenguaje }}
        </span>
      </div>
    </div>

    <div class="question__body" :style="{color: pregunta.curso.color}">
      <h3 
        class="question__body__title"
      >
        {{ pregunta.title }}
      </h3>
      <!-- <a 
        href="#"
        class="question__body__reference"
        v-for="referencia in pregunta.references"
        :key="referencia.id">
          {{ referencia.name }}
      </a> -->
      <p class="question__body__content" v-html="pregunta.content"></p>
    </div>

    <div class="question__footer">
      <div class="question__footer__left">
        <p 
          class="question__footer__left__title">
          Referencias
        </p>
        <div class="question__footer__left__actions">
          <a 
            href="#"
            class="question__footer__left__actions__1"
            v-for="clase in pregunta.clases"
            :key="clase.id">
            {{ clase.name }}
          </a>
          <button
            class="question__footer__left__actions__2"
            @click="toLick()">
            Me gusta
          </button>
        </div>

      </div>
      <div class="question__footer__right">
        <p class="question__footer__right__title">
          Compartir
        </p>
        <a 
          @click="CopyRuta()"
          class="question__footer__right__share">
          <play-circle-outline 
            :size="15">
          </play-circle-outline>
          <span class="question__footer__right__share__span">Copiar Enlace</span>
        </a>
      </div>
    </div>

    <app-msj-alert 
      v-if="showAlert"
      mensaje="Primero Inicia Sesion para realziar esta accion"
      actionMain="De Acuerdo"
      actionClose="Cancelar"
      :link="{name: 'login'}"
      :method="closeAlert"
    />

  </div>

  <div v-else>
    ...cargando...
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import apiPregunta from '@/api/pregunta'

  import logo_neunapp from '@/assets/logo_neunapp.png'
  import AppMsjAlert from '@/components/apps/AppMsjAlert'

  export default {
    name: 'DetallePregunta',
    components: {
      AppMsjAlert
    },
    data() {
      return {
        pregunta: null,
        showAlert: false
      }
    },
    computed: {
      ...mapGetters({
        isLoggedIn: 'isLoggedIn',
      }),
      getPhoto: function () {
        if (this.pregunta.user.avatar) {
          return this.pregunta.user.avatar
        } else {
          return logo_neunapp
        }
      }
    },
    methods: {
      formatDate: function (value) {
        return this.$moment(value).format('DD MMMM YYYY')
      },
      closeAlert: function () {
        this.showAlert = false
      },
      toLick: function () {
        if (!this.isLoggedIn) {
          this.showAlert = true
        } else {
          console.log('enviando peticion ...')
        }
      },
      recuperarPregunta: function () {
        apiPregunta.getPregunta(this.$route.params.slug).then(
          (response) => {
            this.pregunta = response.data
          }
        )
      },
      CopyRuta: function () {
        let ruta = window.location.href
        let texhtml = document.createElement("textarea");
        texhtml.value = ruta
        texhtml.setAttribute('readonly', '');
        texhtml.style.position = 'absolute';
        texhtml.style.top = '-9999px';
        document.body.appendChild(texhtml);
        texhtml.focus();
        texhtml.select();
        document.execCommand('copy');
        document.body.removeChild(texhtml);
        console.log(ruta)
      }
    },
    beforeMount() {
      this.recuperarPregunta()
    },
  }
</script>

<style lang="scss" scoped>
  .question {
    background: $n-color-white;
    margin-bottom: 1em;
    margin-top: 1em;
    display: block;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    padding: 5px;
    border-radius: 5px;
    @include desde(1000px) {
      padding: 14px;
      border-radius: 10px;
    }
    &__head {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__left {
        margin-right: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &__img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
          @include desde(1000px) {
            width: 40px;
            height: 40px;
          }
        }
        &__nike {
          font-family: $n-ff-3;
          font-size: 12px;
          color: $n-color4;
          margin-right: 10px;
          @include desde(1000px) {
            font-size: 14px;
          }
        }
        &__date {
          font-size: 11px;
          color: $n-color3;
          font-family: $n-ff-3;
          @include desde(1000px) {
            font-size: 14px;
            color: $n-color7;
          }
        }
      }
      &__right {
        &__tag {
          font-size: 11px;
          color: $n-color-white;
          font-family: $n-ff-4;
          padding: 5px 10px 5px 10px;
          border-radius: 8px;
          @include desde(1000px) {
            font-size: 14px;
            padding: 5px 20px 5px 20px;
            border-radius: 10px;
          }
        }
      }
    }
    &__body {
      padding: 10px;
      border-left: 2px solid;
      border-radius: 10px;
      margin-top: 10px;
      &__title {
        font-family: $n-ff-1;
        font-size: 14px;
        font-weight: bold;
        color: $n-color2;
        text-transform: capitalize;
        @include desde(1000px) {
          font-size: 20px;
        }
      }
      &__reference {
        display: block;
        font-size: 11px;
        font-family: $n-ff-4;
        margin-bottom: 14px;
        color: $n-color4;
        text-transform: capitalize;
        @include desde(1000px) {
          font-size: 14px;
        }
      }
      &__content {
        font-family: $n-ff-4;
        font-size: 14px;
        color: $n-color3;
        margin-bottom: 0px;
        @include desde(1000px) {
          font-size: 16px;
        }
      }
    }
    &__footer {
      padding: 5px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__left {
        &__title {
          font-size: 11px;
          font-family: $n-ff-1;
          color: $n-color3;
          margin-bottom: 5px;
          margin-top: 5px;
        }
        &__actions {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &__1 {
            font-size: 10px;
            padding: 5px 10px 5px 10px;
            border-radius: 10px;
            color: $n-color-white;
            background: $n-color4;
            margin-bottom: 10px;
            font-family: $n-ff-2;
            &:hover {
              color: $n-color-white;
            }
            @include desde(1000px) {
              font-size: 14px;
              padding: 5px 15px 5px 15px;
            }
          }
          &__2 {
            background: $n-color1;
            font-size: 10px;
            padding: 5px 15px 5px 15px;
            border-radius: 10px;
            color: $n-color-white;
            &:hover {
              cursor: pointer;
              opacity: 0.6;
            }
            @include desde(1000px) {
              font-size: 14px;
              padding: 5px 25px 5px 25px;
            }
          }
        }
      }

      &__right {
        &__title {
          font-size: 12px;
          font-family: $n-ff-4;
          color: $n-color2;
          margin-bottom: 5px;
        }
        &__share {
          font-size: 12px;
          font-family: $n-ff-2;
          display: flex;
          justify-content: center;
          align-items: center;
          color: $n-color2;
          border: 1px solid $n-color7;
          border-radius: 10px;
          padding-top: 5px;
          padding-left: 15px;
          padding-right: 15px;
          @include desde(1000px) {
            font-size: 16px;
            padding: 5px 25px 5px 25px;
          }
          &__span {
            margin-bottom: 5px;
            margin-left: 5px;
          }
        }
      }
    }
  }
</style>