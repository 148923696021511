<template>
  <div class="lego-banner">
    <img 
      class="lego-banner__img"
      src="https://i.imgur.com/brsKv.jpg"
    >
    <div class="lego-banner__copy">
      <h3 class="lego-banner__copy__title">Lego Neunapp</h3>
      <div class="lego-banner__copy__items">
        <v-icon-text-inline
          texto="123 Templates"
          iconComponent="code-braces"
          color="white"
        />
        <v-icon-text-inline
          texto="1232 Componentes"
          iconComponent="code-tags"
          color="white"
        />
        <v-icon-text-inline
          texto="104 Dibujos"
          iconComponent="palette-outline"
          color="white"
        />
      </div>
    </div>
  </div>
</template>

<script>

  import VIconTextInline from '@/components/base/VIconTextInline'

  export default {
    name: 'TheLegoBanner',
    components: {
      VIconTextInline
    }
  }
</script>

<style lang="scss" scoped>
  .lego-banner {
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
    @include desde(720px) {
      height: 230px;
    }
    @include desde (1904px) {
      height: 350px;
    }
    &__img {
      width: 100%;
      height: 100%;
    }
    &__copy {
      width: 100%;
      height: 100px;
      position: absolute;
      bottom: 0px;
      background: rgba(39, 41, 41, 0.404);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      &__title {
        color: $n-color-white;
        font-family: $n-ff-1;
        font-weight: bold;
        font-size: 22px;
        @include desde(720px) {
          font-size: 26px;
        }
      }
      &__items {
        display: none;
        @include desde(720px) {
          display: flex;
          color: white;
        }
      }
    }
  }
</style>