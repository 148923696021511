import Vue from 'vue'
// import vue mometn
const moment = require('moment')
require('moment/locale/es')
// configuracion y ejecucion de librerias y plugins
const momento = Vue.use(require('vue-moment'), {
  moment
})

export default momento
