<template>
  <div class="grid-x grid-margin-x posts">
    <div 
      class="cell small-12"
      v-for="pregunta in listaPreguntas"
      :key="pregunta.id">
      <v-card-post 
        v-if="pregunta.curso"
        :color="pregunta.curso.color"
        :imagen="pregunta.curso.image"
        :contenido="convertirOpsHtml(pregunta.content_json)"
        :fecha="pregunta.created.toString()"
        :tag="pregunta.curso.lenguaje"
        :respuestas="pregunta.num_answer"
        :link="{name:'pregunta-detail', params: {slug: pregunta.slug}}"
      />
      <v-card-post 
        v-else
        color="#3498db"
        :imagen="pregunta.user.avatar"
        :contenido="convertirOpsHtml(pregunta.content_json)"
        :fecha="pregunta.created.toString()"
        tag="Sin publicar"
        :respuestas="pregunta.num_answer"
        :link="{name:'pregunta-detail', params: {slug: pregunta.slug}}"
        :method="abrirVerPregunta"
        action-method="Eliminar"
        :element="pregunta"
      />
    </div>
    <a 
      v-if="moreResults"
      @click="listarPreguntas()"
      class="more-answers__action">
      Cargar más preguntas
    </a>
    
    <question-view-user 
      v-if="showViewQuestion"
      :method="cerrarViewQuestion"
      :question="questionSelect"
    />

    <app-loading v-if="loading"/>
  </div>
</template>

<script>
  import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
  //
  import apiPregunta from '@/api/pregunta'
  //
  import VCardPost from '@/components/base/VCardPost'
  //
  import AppLoading from '@/components/apps/AppLoading'
  import QuestionViewUser from '@/components/users/QuestionViewUser'

  export default {
    name: 'QuestionsUser',
    data() {
      return {
        listaPreguntas: [],
        loading: false,
        moreResults: true,
        page: 0,
        showViewQuestion: false,
        questionSelect: null
      }
    },
    components: {
      VCardPost,
      AppLoading,
      QuestionViewUser
    },
    methods: {
      listarPreguntas: function () {
        if (this.moreResults) {
          this.loading = true
          this.page = this.page + 1
          apiPregunta.preguntasPorUsuario(this.page).then(
            (response) => {
              this.listaPreguntas = this.listaPreguntas.concat(response.data.results)
              this.loading = false
              if (response.data.next) {
                this.moreResults = true
              } else {
                this.moreResults = false
              }
            }
          ).catch(() => {
            this.loading = false
          })
        }
      },
      convertirOpsHtml: function (deltaOps) {
        let delta = JSON.parse(deltaOps)
        let cfg = {}
        let converter = new QuillDeltaToHtmlConverter(delta, cfg)
        return converter.convert()
      },
      abrirVerPregunta: function (pregunta) {
        console.log('abirendo ver pregunta')
        this.showViewQuestion = true
        this.questionSelect = pregunta
      },
      cerrarViewQuestion: function () {
        this.showViewQuestion = false
        this.questionSelect = null
      }
    },
    beforeMount() {
      this.listarPreguntas()
    },
  }
</script>

<style lang="scss" scoped>
  .more-answers {
    padding: 15px;
    border-radius: 20px;
    background: $n-color-white;
    box-shadow: 4px 5px 30px 9px rgba(211,212,214,0.56);
    &__action {
      font-size: 14px;
      font-family: $n-ff-2;
      color: $n-color2;
      display: block;
      width: 100%;
      text-align: center;
      text-decoration: underline;
    }
  }
</style>