import Vue from 'vue'
import Vuex from 'vuex'
// import modules
import moduleUser from './user'
import modulePregunta from './pregunta'
import moduleLego from './lego'
import moduleVenta from './venta'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    moduleUser,
    modulePregunta,
    moduleLego,
    moduleVenta,
  }
})
