<template>
  <div class="grid-x grid-margin-x align-center">
    <app-msj-soporte
      class="cell small-12 medium-8 large-6" 
      titulo="Estamos construyendo estas opciones, pronto estaran listas"
      :link="{name: 'cursos'}"
    />
  </div>
</template>

<script>

  import AppMsjSoporte from '@/components/apps/AppMsjSoporte'

  export default {
    name: 'LegoUser',
    components: {
      AppMsjSoporte
    }
  }
</script>

<style lang="scss" scoped>

</style>