<template>
  <div class="grid-x">
    <div class="cell small-12 medium-6 lego-search">
      <input 
        type="text"
        class="lego-search__in"
        placeholder="Buscar componente"
        v-model="kword"
        v-on:keyup.enter="setKwordPieza(kword)"
      >
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex' 

  export default {
    name: 'TheLegoSearch',
    data() {
      return {
        kword: ''
      }
    },
    methods: {
      ...mapActions({
        setKwordPieza: 'setKwordPieza'
      }),
    },
  }
</script>

<style lang="scss" scoped>
  .lego-search {
    padding: 10px;
    &__in {
      margin: 0px;
      height: 35px;
      border-radius: 10px;
      font-size: 14px;
      font-family: $n-ff-1;
    }
  }
</style>