// importamos vistas locales
import ForoPage from '@/views/foro/ForoPage'
import ForoPreguntasPopulares from '@/views/foro/ForoPreguntasPopulares'
import ForoRespuestas from '@/views/foro/ForoRespuestas'

const foroRutes = [
  {
    path: '/foro',
    component: ForoPage,
    children: [
      {
        path: '',
        name: 'foro',
        component: ForoPreguntasPopulares,
      },
      {
        path: 'respuestas/:slug',
        name: 'pregunta-detail',
        component: ForoRespuestas,
      },
    ]
  }
]

export default foroRutes