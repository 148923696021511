<template>
  <div class="cell grid-x grid-margin-x">
    <div
      class="cell small-12 medium-6 large-3"
      v-for="pieza in listaPiezasLego"
      :key="pieza.id"
    >
      <v-card-image 
        :titulo="pieza.name"
        :precio="parseFloat(pieza.price)"
        :detalle="pieza.tag"
        :mainImage="pieza.image"
        :image="pieza.lenguaje.image"
        :link="{ name: 'lego-detail', params: {'slugLego': pieza.slug} }"
      />
    </div>
    <div v-if="loading">
      <app-loading-image />
    </div>
  </div>
</template>

<script>
  import apiLego from '@/api/lego.js' 
  import { mapGetters } from 'vuex'
  import VCardImage from '@/components/base/VCardImage'
  import AppLoadingImage from '@/components/apps/AppLoadingImage.vue'

  export default {
    name: 'TheLegoGalery',
    components: {
      VCardImage,
      AppLoadingImage
    },
    computed: {
      ...mapGetters({
        kword: 'kwordPieza'
      }),
    },
    watch: {
      kword: function () {
        this.listarPiezas()
      }
    },
    data() {
      return {
        listaPiezasLego: [],
        loading: false
      }
    },
    methods: {
      listarPiezas: function () {
        this.loading = true
        apiLego.buscarPiezasLego(this.$route.params.categoria, this.kword).then(
          (response) => {
            this.loading = false
            this.listaPiezasLego = response.data.results
          }
        ).catch (
          (error) => {
            console.log(error)
            this.loading = false
          }
        )
      }
    },
    beforeMount() {
      this.listarPiezas()
    },
  }
</script>

<style lang="scss" scoped>

</style>