<template>
  <div class="cell grid-x grid-margin-x cursos">
    <h1 class="cell cursos__title">
      {{ titulo }}
    </h1>
    <div 
      class="cell grid-x grid-margin-x"
      v-show="showCtrls">
      <router-link
        :to="{name: 'cursos', params: {filtro: 'populares'}}"
        class="cell small-6 medium-4 large-3 cursos__action color1">
        Ver Todos los Cursos
      </router-link>
      <router-link
        :to="{name: 'cursos', params: {filtro: 'gratis'}}"
        class="cell small-6 medium-4 large-3 cursos__action color2">
        Ver Cursos gratuitos
      </router-link>
    </div>
    <div 
      class="cell small-6 medium-4 large-3"
      v-for="curso in cursos" :key="curso.id">
        <v-card-full 
          :titulo="curso.title"
          :link="{ 
            name: 'curso-detail', 
            params: {uniqueName: curso.unique_name}}"
          :imagen="curso.image"
          :horas="parseInt(curso.hours)"
          :lenguaje="curso.lenguaje"
          :precio="parseFloat(curso.price)"        
        />
    </div>
  </div>
</template>

<script>
  // api
  import apiCursos from '@/api/cursos'
  import VCardFull from '@/components/base/VCardFull'

  export default {
    name: 'ListaCursos',
    data() {
      return {
        cursos: []
      }
    },
    props: {
      titulo: String,
      link: String,
      size: {
        type: Number,
        default: 4
      },
      showCtrls: {
        type: Boolean,
        default: true
      }
    },
    components: {
      VCardFull
    },
    methods: {
      listaCursos: function () {
        apiCursos.cursosPopulares(this.$route.params.filtro).then(
          (response) => {
            this.cursos = response.data.results
          }
        )
      }
    },
    beforeMount() {
      this.listaCursos()
    }
  }
</script>

<style lang="scss" scoped>
 .cursos {
   margin-bottom: 2em;
   &__title {
      font-family: $n-ff-5;
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 1em;
      color: $n-color2;
      @include hasta(1000px) {
        font-size: 18px;
      }
   }
   &__action {
     display: flex;
     width: 100%;
     justify-content: center;
     padding: 10px;
     border-radius: 14px;
     margin-bottom: 1.5em;
     font-family: $n-ff-1;
     font-size: 14px;
     color: $n-color-white;
     @include hasta(1000px) {
        font-size: 11px;
      }
     &:hover {
       color: $n-color2;
     }
     &.color1 {
        background-color: $n-color5; 
     }
     &.color2 {
        background-color: $n-color4; 
     }
   }
 }
</style>