<template>
  <div class="card-info">
    <a
      class="card-info__btn"
      :style="{'background': color}"
      @click="method()">
        {{ textAction }}
    </a>
    <h3 class="card-info__title">
      {{ title }}
    </h3>
    <div class="card-info__details">
      <p 
        v-for="detail in details"
        :key="detail.id" 
        class="card-info__details__item">
          {{ detail.name }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VCardInfo',
    props: {
      color: String,
      textAction: String,
      method: { type: Function },
      title: String,
      details: Array
    }
  }
</script>

<style lang="scss" scoped>
  .card-info {
    width: 100%;
    padding: 14px;
    background: $n-color-white;
    border-radius: 3px;
    &__btn {
      display: block;
      text-align: center;
      color: $n-color-white;
      font-family: $n-ff-1;
      text-transform: capitalize;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 1em;
      &:hover {
        opacity: 0.6;
        color: $n-color-white;
      }
    }
    &__title {
      font-size: 15px;
      font-weight: bold;
      text-align: left;
      font-family: $n-ff-2;
      text-transform: capitalize;
      color: $n-color2;
    }
    &__details {
      padding: 10px;
      font-family: $n-ff-2;
      font-size: 14px;
    }
  }
</style>