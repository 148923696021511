<template>
  <div>
    <button @click="loginGoogle()" class="btn-google">
      Ingresar con Google
    </button>

    <app-loading 
      v-if="loading"    
    />

  </div>
</template>

<script>
  // vuex
  import { mapGetters } from 'vuex'
  import store from '@/store'
  //
  import firebase from 'firebase/app'
  //
  import 'firebase/auth'
  // configuracion
  import { config } from './config'
  // API
  import apiUsers from '@/api/users'

  // 
  import AppLoading from '@/components/apps/AppLoading'

  export default {
    name: 'LoginGoogle',
    components: {
      AppLoading
    },
    data() {
      return {
        loading: false
      }
    },
    computed: {
      ...mapGetters({
        userData: 'userData',
        pending: 'pending'
      })
    },
    methods: {
      loginGoogle: function () {
        firebase.initializeApp(config)
        // se crea instancia de proveedor de google
        var provider = new firebase.auth.GoogleAuthProvider()
        //
        firebase.auth().useDeviceLanguage()
        //
        this.loading = true
        var self = this
        firebase.auth().signInWithPopup(provider).then(
          function(result) {
          // The signed-in user info.
            var user = result.user
            // recuperamos el TokenId
            user.getIdToken().then(
              (token) => {
              // enviamos el token al servidor
              var data = {
                'token_id': token
              }
              // realizamos la consulta al servidro
              apiUsers.loginUser(data).then(
                (response) => {
                  // respuesta del servidor es correcta
                  localStorage.setItem('token', response.data.token)
                  let usuario = response.data.user
                  store.commit('SET_USER', usuario)
                  store.dispatch('IsLogin')
                  self.loading = false
                  self.$router.push({name: 'user-profile'})
                }
              ).catch(
                (error) => {
                  self.loading = false
                  console.log(error)
                }
              )
            })
          }
        ).catch(function(error) {
            // Handle Errors here.
            console.log('error msj : ', error.message)
          }
        )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .btn-google {
    padding: 15px 2em 15px 2em;
    border-radius: 15px;
    background: #e74c3c;
    color: #ecf0f1;
    font-family: $n-ff-1;
    font-size: 14px;
    &:hover {
      cursor: pointer;
    }
  }
</style>