<template>
  <div>
    <detail-curso />
  </div>
</template>

<script>

  import DetailCurso from '@/components/cursos/DetailCurso'

  export default {
    name: 'CursoDetailPage',
    components: {
      DetailCurso
    }
  }
</script>

<style lang="scss" scoped>

</style>