import Vue from "vue";
//
import MenuIcon from 'vue-material-design-icons/Menu.vue';
import AccountBox from 'vue-material-design-icons/AccountBox.vue';
import AccountOutline from 'vue-material-design-icons/AccountOutline.vue';
import BookOpen from 'vue-material-design-icons/BookOpen.vue';
import CalendarMultiselect from 'vue-material-design-icons/CalendarMultiselect.vue';
import Storefront from 'vue-material-design-icons/Storefront.vue';
import StoreOutline from 'vue-material-design-icons/StoreOutline.vue';
import Login from 'vue-material-design-icons/Login.vue';
import DatabaseSearch from 'vue-material-design-icons/DatabaseSearch.vue';
import SearchWeb from 'vue-material-design-icons/SearchWeb.vue';
import Magnify from 'vue-material-design-icons/Magnify.vue';
import Eye from 'vue-material-design-icons/Eye.vue';
import CommentSearchOutline from 'vue-material-design-icons/CommentSearchOutline.vue';
import BookMultiple from 'vue-material-design-icons/BookMultiple.vue';
import Send from 'vue-material-design-icons/Send.vue';
import PlayCircleOutline from 'vue-material-design-icons/PlayCircleOutline.vue';
import PlayCircle from 'vue-material-design-icons/PlayCircle.vue';
import NewspaperVariantOutline from 'vue-material-design-icons/NewspaperVariantOutline.vue';
import AnimationPlayOutline from 'vue-material-design-icons/AnimationPlayOutline';
import Cart from 'vue-material-design-icons/Cart';
import AccountGroup from 'vue-material-design-icons/AccountGroup';
import ClockOutline from 'vue-material-design-icons/ClockOutline';
import CodeBraces from 'vue-material-design-icons/CodeBraces';
import CodeTags from 'vue-material-design-icons/CodeTags';
import PaletteOutline from 'vue-material-design-icons/PaletteOutline';
import Twitter from 'vue-material-design-icons/Twitter';
import Youtube from 'vue-material-design-icons/Youtube';
import Patreon from 'vue-material-design-icons/Patreon';
import Logout from 'vue-material-design-icons/Logout';
import CommentQuestion from 'vue-material-design-icons/CommentQuestion';
import TextBox from 'vue-material-design-icons/TextBox';
import DesktopMacDashboard from 'vue-material-design-icons/DesktopMacDashboard';
import CardBulleted from 'vue-material-design-icons/CardBulleted';
import ImageArea from 'vue-material-design-icons/ImageArea';
import FormSelect from 'vue-material-design-icons/FormSelect';
import LanguageJavascript from 'vue-material-design-icons/LanguageJavascript';
import CodeBracesBox from 'vue-material-design-icons/CodeBracesBox';
import IframeBraces from 'vue-material-design-icons/IframeBraces';


//mdi-patreon
Vue.component('menu-icon', MenuIcon);
Vue.component('account-box', AccountBox);
Vue.component('account-outline', AccountOutline);
Vue.component('book-open', BookOpen);
Vue.component('calendar-multiselect', CalendarMultiselect);
Vue.component('storefront', Storefront);
Vue.component('store-outline', StoreOutline);
Vue.component('login', Login);
Vue.component('database-search', DatabaseSearch);
Vue.component('search-web', SearchWeb);
Vue.component('magnify', Magnify);
Vue.component('eye', Eye);
Vue.component('comment-search-outline', CommentSearchOutline);
Vue.component('book-multiple', BookMultiple);
Vue.component('send', Send);
Vue.component('play-circle-outline', PlayCircleOutline);
Vue.component('play-circle', PlayCircle);
Vue.component('newspaper-variant-outline', NewspaperVariantOutline);
Vue.component('animation-play-outline', AnimationPlayOutline);
Vue.component('cart', Cart);
Vue.component('account-group', AccountGroup);
Vue.component('clock-outline', ClockOutline);
Vue.component('code-braces', CodeBraces);
Vue.component('code-tags', CodeTags);
Vue.component('palette-outline', PaletteOutline);
Vue.component('twitter', Twitter);
Vue.component('youtube', Youtube);
Vue.component('patreon', Patreon);
Vue.component('logout', Logout)
Vue.component('comment-question', CommentQuestion)
Vue.component('text-box', TextBox)
Vue.component('desktop-mac-dashboard', DesktopMacDashboard)
Vue.component('card-bulleted', CardBulleted)
Vue.component('image-area', ImageArea)
Vue.component('form-select', FormSelect)
Vue.component('language-javascript', LanguageJavascript)
Vue.component('code-braces-box', CodeBracesBox)
Vue.component('iframe-braces', IframeBraces)