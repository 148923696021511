import { render, staticRenderFns } from "./PaymentProcess.vue?vue&type=template&id=0460b9c6&scoped=true&"
import script from "./PaymentProcess.vue?vue&type=script&lang=js&"
export * from "./PaymentProcess.vue?vue&type=script&lang=js&"
import style0 from "./PaymentProcess.vue?vue&type=style&index=0&id=0460b9c6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0460b9c6",
  null
  
)

export default component.exports